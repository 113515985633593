<template>
  <p>The shipment attributes and raw data are listed below.</p>
  <Panel
    v-model:collapsed="isAttributesCollapsed"
    :collapsed="false"
    class="mb-2"
  >
    <template #header>
      <span class="p-panel-header__title">Shipment Attributes</span>
      <i :class="getExpanderIcon(isAttributesCollapsed)" />
    </template>
    <DataTable :value="extractionResults" data-cy="shipment-attributes">
      <Column field="attribute" header="Attribute" class="w-50" />
      <Column field="value" header="Value" class="w-50">
        <template #body="{ data }">
          <div class="table-content">
            <ShipmentDetailsTableElement
              :element-value="data.extractedValue"
              :element-type="data.valueType"
              :error-message="data.error"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </Panel>
  <Panel v-model:collapsed="isJsonViewerCollapsed" :collapsed="false">
    <template #header>
      <span class="p-panel-header__title">Raw Data</span>
      <i :class="getExpanderIcon(isJsonViewerCollapsed)" />
    </template>
    <json-viewer
      :value="shipmentRawData"
      :expand-depth="10"
      :expanded="true"
      boxed
      copyable
      data-cy="shipment-json"
      @copied="onCopyClick"
    >
      <template #copy>
        <Button class="p-button p-button-tertiary">
          <i class="pi pi-clone" /> {{ copyButtonText }}
        </Button>
      </template>
    </json-viewer>
  </Panel>
</template>

<script setup>
import Panel from "primevue/panel";
import DataTable from "primevue/datatable";
import JsonViewer from "vue-json-viewer";
import Column from "primevue/column";
import { onMounted, ref } from "vue";
import Button from "primevue/button";
import { useCopiedState } from "@/services/utils/utils";
import ShipmentDetailsTableElement from "@/components/shipments/ShipmentDetailsTableElement.vue";

defineProps({
  extractionResults: {
    type: Array,
    default: () => [],
  },
  shipmentStatus: {
    type: String,
    default: "",
  },
  shipmentRawData: {
    type: Object,
    default: () => {},
  },
});
const { copyButtonText, changeStateOnClick } = useCopiedState("Copy", "Copied");
const onCopyClick = () => changeStateOnClick();

const isAttributesCollapsed = ref(false);
const isJsonViewerCollapsed = ref(true);
const getExpanderIcon = (isCollapsed) =>
  isCollapsed ? "pi pi-plus" : "pi pi-minus";

onMounted(() => {
  /* Toggleable behaviour is provided by primevue Panel, however it has limitations.
  It provides icon and only by clicking on icon we can expand/collapse tab.

  That's workaround for that behaviour. We removed icon from Panel and event listener on all tab
  and add our own icons.
   */
  const tableHeader = document.getElementsByClassName("p-panel-header")[0];
  const jsonViewerHeader = document.getElementsByClassName("p-panel-header")[1];

  if (tableHeader) {
    tableHeader.addEventListener("click", () => {
      isAttributesCollapsed.value = !isAttributesCollapsed.value;
    });
  }
  if (jsonViewerHeader) {
    jsonViewerHeader.addEventListener("click", () => {
      isJsonViewerCollapsed.value = !isJsonViewerCollapsed.value;
    });
  }
});
</script>
<style scoped lang="scss">
:deep(.p-datatable-thead) {
  display: none;
}

:deep(.p-panel-content) {
  border: none;
}

:deep(.p-datatable .p-datatable-tbody > tr > td) {
  height: 56px;
  border-bottom: 1px solid #dee2e6;
}

:deep(.p-datatable-table) {
  table-layout: fixed;
}

:deep(.p-panel-content:has(.jv-container)) {
  padding: 1.25rem 0 0 0;
}

:deep(.p-panel-header) {
  background: #f8f9fa;
  border: none;
  border-radius: 8px;
  height: 46px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 24px;

  &:hover {
    cursor: pointer;
  }
}

:deep(.jv-container) {
  padding: 16px 16px;
  background: #fafafa;
}

:deep(.jv-container .jv-button) {
  padding: 0;
}

:deep(.jv-container.boxed:hover) {
  box-shadow: none;
  border-color: #eeeeee;
}

.p-panel-header__title {
  margin-right: auto;
}

.error-icon .pi {
  font-size: 18px;
  margin-left: 4px;
  margin-top: 3px;
}

.table-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.table-content__list {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
</style>
