<template>
  <MainPage
    :error-message="errorMessage"
    :loading="isLoading"
    data-cy="list-template-mappings-page"
    :heading="mainPageConfig.heading"
    :tagline="mainPageConfig.tagline"
    :show-footer="false"
    @wipe-error="wipeError"
  >
    <template v-if="!isEmpty" #actionsBar>
      <Button
        label="New Data Mapping"
        class="p-button"
        icon="pi pi-plus"
        data-cy="create-template-data-mapping"
        @click="onOpenDialog()"
      />
    </template>
    <template #body>
      <div class="card shadow">
        <div class="card-body">
          <EmptyPage
            v-if="isEmpty && !isLoading"
            :empty-config="emptyMessageConfig"
            @button-primary-action="onOpenDialog()"
          />
          <DataMappingTable v-model:dataMappings="templateMappings" />
        </div>
      </div>
    </template>
  </MainPage>
</template>
<script setup>
import Button from "primevue/button";
import { computed, onMounted, ref } from "vue";
import DataMappingTable from "@/components/template-data-mapping/DataMappingTable.vue";
import EmptyPage from "@/components/EmptyPage";
import { useTemplateMappingsStore } from "@/components//template-data-mapping/stores/templateMappings.js";
import { storeToRefs } from "pinia";
import { useTemplateMappingDialog } from "@/components/template-data-mapping/helpers/modalConfigs";
import { useDataMappingEmptyState } from "@/components/template-data-mapping/helpers/emptyStateConfig";
import { useQueryCompanyState } from "@/queries/queriesCompany";

const isLoading = ref(true);
const errorMessage = ref("");
const wipeError = () => {
  errorMessage.value = "";
};

const store = useTemplateMappingsStore();
const { templateMappings } = storeToRefs(store);
const { getTemplateMappings } = store;

onMounted(async () => {
  isLoading.value = true;
  await getTemplateMappings();
  isLoading.value = false;
});

const mainPageConfig = computed(() => {
  return {
    heading: isEmpty.value ? "" : "Dynamic Content",
    tagline: isEmpty.value ? "" : tagline,
  };
});

const { emptyConfig, initialConfig, tagline } = useDataMappingEmptyState();
const { data, isFetched } = useQueryCompanyState();
const isEmpty = computed(() => !templateMappings.value.length);

const emptyMessageConfig = computed(() => {
  if (isEmpty.value && isFetched.value) {
    if (data.value.hasCreatedTemplateMapping) {
      return emptyConfig;
    } else {
      return initialConfig;
    }
  }
  return {};
});

const onOpenDialog = () => {
  openTemplateMappingDialog({});
};
const { openTemplateMappingDialog } = useTemplateMappingDialog();
</script>
<style scoped>
.card-body {
  padding: 32px;
}
</style>
