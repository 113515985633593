<template>
  <aside class="nav-bar" data-cy="side-navigation">
    <nav class="nav" aria-label="navigation">
      <div class="nav-section">
        <div class="sidebar-brand mx-auto">
          <img
            class="d-block mx-auto logo"
            src="@/assets/img/Scurri-Track-Plus-Logo.svg"
            alt="Scurri Track Plus Logo"
            @click="redirectToMain"
          />
        </div>
        <NavbarMainMenu ref="mainMenu" />
      </div>
      <div class="nav-section">
        <NavbarSecondaryMenu @menu-item-clicked="onSubMenuSelect" />
      </div>
    </nav>
  </aside>
</template>

<script setup>
import { useRouter } from "vue-router";
import NavbarMainMenu from "@/components/navbar/NavbarMainMenu.vue";
import NavbarSecondaryMenu from "@/components/navbar/NavbarSecondaryMenu.vue";
import { ref } from "vue";

const router = useRouter();

const redirectToMain = () => {
  router.push("/");
};

const mainMenu = ref(null);

const onSubMenuSelect = () => {
  mainMenu.value?.resetExpandedKeys();
};
</script>
<style scoped lang="scss">
@import "@/scss/colors.scss";

// Overrides
.logo {
  display: flex;
  width: 148.72px;
  height: 52px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 3.12px;
  flex-shrink: 0;

  &:hover {
    cursor: pointer;
    border-radius: 6px;
  }
}

.nav-bar {
  font-size: 14px;
  max-width: 240px;
  width: 240px;

  .nav {
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;

    .nav-section {
      width: 100%;
      margin-bottom: 8px;
    }
  }
}
.sidebar-brand {
  border-bottom: 1px solid #1f2a4e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 104px;
  width: 100%;

  img {
    height: 52px;
  }
}
</style>
