import { httpRequest } from "@/services/apiRequests";

class AuthApiRequests {
  static _extractSessionInfoFromResponse(data) {
    return {
      isAuthenticated: data.is_authenticated,
      csrfToken: data.csrf_token,
    };
  }

  static _extractNullSessionInfoFromResponse() {
    return {
      isAuthenticated: false,
      csrfToken: null,
    };
  }

  static async getCsrfTokenFromBackend() {
    try {
      const { data } = await httpRequest.get("acct-auth-process/session/");
      return this._extractSessionInfoFromResponse(data);
    } catch {
      return this._extractNullSessionInfoFromResponse();
    }
  }

  static async logout() {
    let error;

    try {
      await httpRequest.post("acct-auth-process/logout/");
    } catch (e) {
      // Do not return any error when we have 401. In case of 401 we want to just redirect,
      // because user will not be able to do any action anyway.
      if (e.status !== 401) {
        error = e.response;
      }
    }
    return { error };
  }

  static async initSSO(lastVisitedPage) {
    let data;
    let error;
    try {
      ({ data } =
        (await httpRequest.post("acct-auth-process/sso-init/", {
          last_visited_page: lastVisitedPage,
        })) || {});
    } catch (e) {
      error = e.response;
    }
    return { redirectUrl: data?.redirect_url, error };
  }

  static async loginIn(credentials, sessionToken) {
    let data;
    let error;

    const { username, password } = credentials;
    try {
      ({ data } =
        (await httpRequest.post("acct-auth-process/sso-login/", {
          username,
          password,
          session_token: sessionToken,
        })) || {});
    } catch (e) {
      error = e.response;
    }
    return { redirectUrl: data?.redirect_url, error };
  }

  static async completeLogin(authToken) {
    let data;
    let error;

    try {
      ({ data } =
        (await httpRequest.post("acct-auth-process/sso-complete/", {
          auth_token: authToken,
        })) || {});
    } catch (e) {
      error = e.response;
    }
    return { redirectUrl: data?.redirect_url, error };
  }
}

export default AuthApiRequests;
