<template>
  <div>
    <PanelMenu
      id="primary-links"
      :expanded-keys="expandedKeys"
      :model="primaryLinks"
      @update:expanded-keys="setExpandKey"
    >
      <template #item="{ item }">
        <router-link
          v-if="item.route"
          v-slot="{ href, navigate }"
          :to="item.route"
          custom
        >
          <div class="panel-menu-link" data-cy="panel-item-link">
            <a
              :href="href"
              class="panel-menu-link__link"
              :class="{ active: isSubMenuItemActive(item) }"
              @click="navigate"
            >
              <span :class="item.icon" />
              <span class="panel-menu-link__text">{{ item.label }}</span>
            </a>
          </div>
        </router-link>
        <span
          v-else
          class="panel-menu-item"
          data-cy="panel-item-header"
          :class="{ active: isMenuItemActive(item) }"
        >
          <span :class="item.icon" class="p-menuitem-icon" />
          <span class="panel-menu-item__text">
            {{ item.label }}
          </span>
          <span
            v-if="item.items"
            class="p-submenu-icon p-menuitem-icon expand-icon"
            :class="getExpandIcon(item)"
          />
        </span>
      </template>
    </PanelMenu>
  </div>
</template>
<script setup>
import PanelMenu from "primevue/panelmenu";
import { onMounted, ref } from "vue";
import { useAnalyticsQueryClient } from "@/queries/queriesAnalytics";
import { useFeatureFlagManager } from "@/features";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router/dist/vue-router";

const expandedKeys = ref({});
const primaryLinks = ref([
  {
    key: "10",
    label: "Shipments",
    icon: "pi pi-truck",
    items: [
      {
        label: "Active Shipments",
        route: { name: "ListShipments" },
      },
      {
        label: "Past Shipments",
        route: { name: "ListPastShipments" },
      },
    ],
  },
  {
    key: "20",
    label: "Notifications",
    icon: "pi pi-envelope",
    items: [
      {
        label: "Automations",
        route: { name: "ListNotificationWorkflows" },
      },
      {
        label: "Emails",
        route: { name: "NotificationsPage" },
      },
    ],
  },
  {
    key: "30",
    label: "Tracking",
    icon: "pi pi-map-marker",
    items: [
      {
        label: "Branded Page",
        route: { name: "ListTrackingPages" },
      },
      {
        label: "Widget",
        route: { name: "TrackingWidget" },
      },
    ],
  },
]);

const route = useRoute();
const router = useRouter();

const getRouterPath = (route) => {
  const resolveArgs = {
    name: route.name,
    ...(route.params && { params: route.params }),
  };
  return router.resolve(resolveArgs) || null;
};

const isSubMenuItemActive = (item) => {
  const resolvedRoute = getRouterPath(item.route);
  const currentRouteMetaGroup = route.meta.group;

  if (!resolvedRoute) return false;

  if (resolvedRoute.meta.group && currentRouteMetaGroup) {
    const groupMatches = resolvedRoute.meta.group === route.meta.group;

    // If groups match, validate params if defined
    if (groupMatches && item.route.params) {
      return Object.entries(item.route.params).every(
        ([key, value]) => route.params[key] === value,
      );
    }
    return groupMatches;
  }
  return false;
};

const isMenuItemActive = (item) => {
  return item.items.some((i) => isSubMenuItemActive(i));
};

const getExpandIcon = (item) => {
  return item.key in expandedKeys.value
    ? "pi pi-angle-down"
    : "pi pi-angle-right";
};

const resetExpandedKeys = () => {
  expandedKeys.value = {};
};

const setExpandKey = (expandKey) => {
  /*
    Setter for managing expanded keys.

    The primary purpose of this function is to ensure that only one menu item is expanded at a time.

    Input:
    - An event object from the PanelModel of PrimeVue.
    - This input is cumulative, meaning PrimeVue provides a list of all currently expanded items,
      not just the most recently expanded one.

    Functionality:
    - This setter ensures that regardless of the input, only a single menu item remains expanded at any given time.
  */

  // Helper function to filter integer keys
  const getIntKeys = (obj) =>
    Object.keys(obj).filter((key) => Number.isInteger(Number(key)));

  // Get integer keys from expandKey and expandedKeys
  const expandKeyInts = getIntKeys(expandKey);
  const currentKeys = getIntKeys(expandedKeys.value);

  // Clear expandedKeys if no valid integer keys
  if (expandKeyInts.length === 0) {
    expandedKeys.value = {};
    return;
  }

  // Check if expandedKeys needs updating
  const hasNewKeys = expandKeyInts.some(
    (key) =>
      !currentKeys.includes(key) || expandedKeys.value[key] !== expandKey[key],
  );

  if (!hasNewKeys) return; // No changes required

  // Update expandedKeys with only the new keys and their values
  expandedKeys.value = expandKeyInts.reduce((result, key) => {
    if (
      !currentKeys.includes(key) ||
      expandedKeys.value[key] !== expandKey[key]
    ) {
      result[key] = expandKey[key];
    }
    return result;
  }, {});
};

const { fetchAnalyticsDashboards } = useAnalyticsQueryClient();
const { isAnalyticsEnabled } = useFeatureFlagManager();

const getAnalyticsMenu = (data) => {
  return {
    key: "40",
    label: "Analytics",
    icon: "pi pi-chart-bar",
    items: data.map((d) => ({
      route: {
        name: "AnalyticsDashboard",
        params: { slug: d.slug },
      },
      label: d.dashboardName,
    })),
  };
};

onMounted(async () => {
  if (await isAnalyticsEnabled()) {
    const data = await fetchAnalyticsDashboards();
    if (Array.isArray(data) && data.length) {
      const analyticsMenu = getAnalyticsMenu(data);
      primaryLinks.value.push(analyticsMenu);
    }
  }

  const initialActiveItemKey =
    primaryLinks.value.find((link) => isMenuItemActive(link)) || {};
  expandedKeys.value[initialActiveItemKey.key] = true;
});

defineExpose({ resetExpandedKeys });
</script>

<style scoped lang="scss">
@import "@/scss/colors.scss";

// Overrides
:deep(#primary-links.p-panelmenu) {
  padding: 24px 8px;

  & .p-panelmenu-header {
    .p-panelmenu-header-content {
      background: #101b40;
      border: unset;
    }
    &:not(.p-disabled).p-highlight .p-panelmenu-header-content {
      background: $dark-blue;
      border: none;
      box-shadow: none;
    }
    &:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content {
      background: $dark-blue !important;
    }

    &:not(.p-disabled):focus .p-panelmenu-header-content {
      box-shadow: unset;
    }
  }

  & .p-panelmenu-content {
    border: unset;
    background: $dark-blue;
    padding: 0;

    .p-submenu-list:not(.p-panelmenu-root-submenu) {
      display: flex;
      flex-direction: column;
      gap: 4px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: calc(1rem + 8px);
        bottom: 0;
        width: 1px; /* Border width */
        background-color: rgba(255, 255, 255, 0.1); /* Border color */
        pointer-events: none; /* Ensure it doesn’t interfere with interactions */
      }

      .p-menuitem {
        background: $dark-blue !important;

        & > .p-menuitem-content {
          padding-left: 24px;
        }

        &:not(.p-highlight):not(.p-disabled) {
          & > .p-menuitem-content:hover,
          &.p-focus > .p-menuitem-content {
            background: $dark-blue !important;
          }
        }
      }

      a {
        border-left: unset !important;
        margin-left: 0 !important;
      }
    }
  }

  & .p-panelmenu-panel {
    margin-bottom: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

:deep(.p-menuitem-icon) {
  margin-right: 0;
}

//Custom styling
.panel-menu-item {
  border-radius: 6px;
  display: flex;
  height: 40px;
  padding: 13px 16px;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.03);
    color: rgba(255, 255, 255, 0.8) !important;
  }

  &.active {
    &:hover {
      background: #1f2a4e;
    }

    & > .panel-menu-item__text {
      color: #fff !important;
    }

    & > .p-menuitem-icon {
      color: #26d466 !important;
    }

    & > .expand-icon {
      color: #fff !important;
    }
  }

  & > .panel-menu-item__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    width: 100%;
  }
}

.panel-menu-link {
  padding-left: 12px;

  .p-menuitem-text {
    margin-left: -10px;
  }

  &__link {
    height: 36px;
    display: flex;
    padding: 0 16px;
    align-items: center;
    text-decoration: unset;
    border-radius: 6px;
    color: rgba(255, 255, 255, 0.6) !important;
    transition: 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.8) !important;
    }
  }

  & .active {
    border-radius: 6px;
    background: #1f2a4e;

    & > .panel-menu-link__text {
      color: #fff;
    }

    & > &:hover {
      background: #1f2a4e;
    }
  }
}

:deep(.p-menuitem-text),
:deep(.p-menuitem-icon) {
  color: $mid-grey !important;
  margin-right: 8px;
}

:deep(.p-toggleable-content-enter-from),
:deep(.p-toggleable-content-leave-to) {
  max-height: 0;
  opacity: 0;
}

:deep(.p-toggleable-content-enter-to),
:deep(.p-toggleable-content-leave-from) {
  max-height: 1000px;
  opacity: 1;
}

:deep(.p-toggleable-content-leave-active) {
  overflow: hidden;
  transition:
    max-height 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    opacity 0.1s ease-out;
}

:deep(.p-toggleable-content-enter-active) {
  overflow: hidden;
  transition: max-height 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
</style>
