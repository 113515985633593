import {
  ACTIVE,
  EVENT_STATUS_DISPLAY_MAP,
  IMPORT_FEEDBACK_ERROR,
  IMPORT_FEEDBACK_OK,
  ROUTING_FAILED_STATUS,
  STATUS_DISPLAY_MAP,
  UNAVAILABLE,
} from "@/constants";
import {
  getFormatedAwareDate,
  getFormatedNaiveDate,
} from "@/services/utils/datesFormatter";
import {
  ICON_FAILED_NOTIFICATION,
  ICON_PENDING_NOTIFICATION,
  ICON_TRIGGERED_NOTIFICATION,
} from "@/services/utils/iconsMapper";
import { httpRequest } from "@/services/apiRequests";
import { serializeShipmentDetails } from "@/components/shipments/helpers/serializers";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";

class ShipmentsApiRequests {
  static async getTrackingEvents(shipmentId) {
    const { data } = await httpRequest.get(`shipments/${shipmentId}/events/`);
    return {
      events: data.events.map((event) => ({
        timestamp: event.timestamp,
        status: event.status,
        description: event.description,
        location: event.location,
        actionStatuses: event.action_statuses.map((action) => ({
          name: action.name,
          status: action.status,
        })),
      })),
    };
  }

  static async createDummyEvent(shipment_id, status) {
    return await httpRequest.post(
      `shipments/${shipment_id}/dummy-events/`,
      JSON.stringify({
        status: status,
      }),
    );
  }
  static async reRouteSingleShipment(shipmentId) {
    return await httpRequest.post(`shipments/reroute/${shipmentId}`);
  }

  static async reRouteShipments() {
    return await httpRequest.post("shipments/reroute/");
  }

  static async getPortalUrl(shipmentId) {
    const { data } = await httpRequest.get(
      `tracking-page/portal/${shipmentId}`,
    );
    return data;
  }

  static async fetchShipments(query = {}) {
    const params = query
      ? {
          limit: query.limit,
          offset: query.offset,
          status: query.status,
          tracking_number: query.tracking_number,
          latest_event: query.latest_event,
          lifecycle_status: query.lifecycle_status,
          order_by: query.order_by,
        }
      : {};
    const { data } = await httpRequest.get("shipments/", {
      params,
    });
    return { count: data.count, results: data.results };
  }

  static makeImportFeedback(shipment) {
    if (shipment.import_feedback) {
      return shipment.import_feedback;
    }

    // Create import feedback from the legacy payload
    return shipment.error_message
      ? {
          status: IMPORT_FEEDBACK_ERROR,
          message: shipment.error_message,
        }
      : {
          status: IMPORT_FEEDBACK_OK,
          message: "",
        };
  }

  static async fetchShipmentForListPage(query = {}) {
    const { count, results } = await this.fetchShipments(query);
    const shipments = [];
    results.forEach((shipment) => {
      const mappedShipment = {
        status: STATUS_DISPLAY_MAP[shipment.status],
        id: shipment.id,
        tracking_number: shipment.tracking_number ?? "",
        created: getFormatedAwareDate(shipment.created),
        updated: getFormatedNaiveDate(shipment.updated),
        latest_event:
          EVENT_STATUS_DISPLAY_MAP[
            shipment.latest_event ? shipment.latest_event : UNAVAILABLE
          ],
        import_feedback: this.makeImportFeedback(shipment),
        notifications_send: shipment.notifications_send,
        notifications_failed: shipment.notifications_failed,
        notifications_pending: shipment.notifications_pending,
        scheduled_actions_counts_display: {},
        carrier: shipment.carrier,
        lifecycle_status: shipment.lifecycle_status,
      };
      if (shipment.status !== ROUTING_FAILED_STATUS) {
        if (mappedShipment.notifications_send) {
          mappedShipment.scheduled_actions_counts_display.success = {
            count: mappedShipment.notifications_send,
            icon: ICON_TRIGGERED_NOTIFICATION,
            tooltip:
              mappedShipment.notifications_send + " triggered successfully",
          };
        }
        if (mappedShipment.notifications_failed) {
          mappedShipment.scheduled_actions_counts_display.fail = {
            count: mappedShipment.notifications_failed,
            icon: ICON_FAILED_NOTIFICATION,
            tooltip: mappedShipment.notifications_failed + " failed to trigger",
          };
        }
        if (mappedShipment.notifications_pending) {
          mappedShipment.scheduled_actions_counts_display.pending = {
            count: mappedShipment.notifications_pending,
            icon: ICON_PENDING_NOTIFICATION,
            tooltip: mappedShipment.notifications_pending + " pending",
          };
        }
      } else {
        const lifecycleStatus = mappedShipment.lifecycle_status;
        mappedShipment.scheduled_actions_counts_display.no_actions_info = {
          msg: "No Campaign",
          tooltip:
            lifecycleStatus === ACTIVE
              ? "No campaign assigned. Please review your campaign segmentation."
              : "No notifications as this shipment could not be assigned a campaign.",
        };
      }
      shipments.push(mappedShipment);
    });
    return {
      count,
      shipments,
    };
  }

  static async bulkDeleteImportError() {
    return await httpRequest.post("shipments/bulk-delete-import-error/");
  }

  static async getShipmentsStats() {
    const { data } = await httpRequest.get("shipments-stats/");

    return {
      totalCounts: {
        error: data.total_counts.error,
        noRouteAssigned: data.total_counts.no_route_assigned,
        routeAssigned: data.total_counts.route_assigned,
        totalCombined: data.total_counts.total_combined,
      },
    };
  }

  static async getShipment(shipmentId) {
    const { data } = await httpRequest.get(`shipments/${shipmentId}/`);
    return { data };
  }

  static async getShipmentDetails(shipmentId) {
    const { makeRequest } = useConfigurationRequests();
    const { response, error } = await makeRequest({
      endpoint: async () => await this.getShipment(shipmentId),
    });
    let serializedShipment;
    if (!error.value) {
      serializedShipment = serializeShipmentDetails(response.value.data);
    }
    return {
      serializedShipment,
      error: error.value,
    };
  }
}

export default ShipmentsApiRequests;
