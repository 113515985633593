import { useCompanyStateManagement } from "@/queries/queriesCompany";

export const useFeatureFlagManager = () => {
  const { asyncFetchAndVerifyFlag, fetchAndVerifyFlag } =
    useCompanyStateManagement();

  const isPortalPreviewEnabled = async () => {
    return await asyncFetchAndVerifyFlag("scout:flag_can_preview_portal");
  };

  const isBrandsEnabled = async () => {
    return await asyncFetchAndVerifyFlag("scout:flag_rollout_can_view_brands");
  };

  const isCsvImportEnabled = () => {
    return fetchAndVerifyFlag("allow_csv_shipment_import");
  };

  const isAnalyticsEnabled = async () => {
    return await asyncFetchAndVerifyFlag("scout:flag_can_use_analytics");
  };

  return {
    isPortalPreviewEnabled,
    isBrandsEnabled,
    isCsvImportEnabled,
    isAnalyticsEnabled,
  };
};
