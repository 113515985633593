<template>
  <component
    :is="componentTag"
    ref="elementRef"
    v-tooltip.top="tooltipConfig"
    :href="href"
    :target="target"
    class="table-content-elem"
    :class="$attrs.class"
  >
    <span
      v-if="isError"
      v-tooltip.top="errorMessage"
      class="table-badge table-badge--error"
    >
      Error <i class="pi pi-exclamation-circle error-icon" />
    </span>
    <span
      v-else-if="isValueNotProvided"
      class="table-badge table-badge--warning"
    >
      Not Provided
    </span>
    <span v-else>
      {{ elementValue }}
    </span>
  </component>
</template>
<script setup>
import { computed, ref, toRefs } from "vue";
import { isElemUrl, useEllipseChecker } from "@/services/utils/utils";
import { EXTRACTION_RESULT_ERROR_TYPE } from "@/components/shipments/helpers/constants";

const props = defineProps({
  elementValue: {
    type: null, // Accepts any type
    required: true,
  },
  elementType: {
    type: String,
    required: true,
  },
  errorMessage: {
    type: String,
    default: "",
  },
});

defineOptions({
  inheritAttrs: false,
});

const elementRef = ref(null);
const { elementValue, elementType } = toRefs(props);

const isValueUrl = computed(() => isElemUrl(elementValue.value));
const componentTag = computed(() => (isValueUrl.value ? "a" : "span"));
const href = computed(() => (isValueUrl.value ? elementValue.value : ""));
const target = computed(() => (isValueUrl.value ? "_blank" : ""));

const isValueNotProvided = computed(() => {
  return elementValue.value === null || elementValue.value === "";
});
const isError = computed(() => {
  return (
    isValueNotProvided.value &&
    elementType.value === EXTRACTION_RESULT_ERROR_TYPE
  );
});

const tooltipConfig = computed(() =>
  !isValueUrl.value && isEllipse.value
    ? {
        value: `${elementValue.value}`,
        showDelay: 500,
        hideDelay: 200,
      }
    : "",
);

const { isEllipse } = useEllipseChecker(elementRef);
</script>
<style scoped lang="scss">
a.table-content-elem {
  transition: all 0.3s ease;

  &:hover {
    color: #282e38;
  }
}

.table-content-elem {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #495057;
}

.error-icon {
  color: #a60d2d;
}

.table-badge {
  display: flex;
  height: 31.5px;
  padding: 5.25px 10.5px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;

  &--warning {
    border: 1px solid #dee2e6;
    background: #f7f8f9;
    color: #495057;
  }

  &--error {
    border: 1px solid #fccad5;
    background: #fff2f5;
    color: #a60d2d;
  }
}
</style>
