import {
  COMPLETED,
  EVENT_STATUS_DISPLAY_MAP,
  EXPIRED,
  NO_DATA,
  UNAVAILABLE,
} from "@/constants";
import { getNotificationStatusMapDisplay } from "@/components/shipments/helpers/utils";
import { formatHash } from "@/services/utils/hashFormatter";

export function _filterOutEmptyArrayValues(value) {
  /* We want to filter out empty values from list. If list is empty after filter,
  we change that to empty string */
  if (Array.isArray(value)) {
    const filteredArray = value.filter((el) => {
      return el !== null && el !== "";
    });
    if (!filteredArray.length) {
      return "";
    }
    return filteredArray;
  } else {
    return value;
  }
}

export const serializeShipmentDetails = (data) => {
  return {
    id: data.id,
    trackingNumber: data.tracking_number || NO_DATA,
    shipmentStatus: data.status,
    shipmentCreatedTimestamp: data.created,
    updated: data.updated,
    shipmentRawData: data.raw_data,
    latestEvent: {
      status: data.latest_event || UNAVAILABLE,
      timestamp: data.latest_event_timestamp,
    },
    lifecycleStatus: data.lifecycle_status,
    extractionResults: data.extraction_results.map((record) => ({
      attribute: record.attribute,
      extractedValue: _filterOutEmptyArrayValues(record.extracted_value.value),
      valueType: record.extracted_value.type,
      error: record.error,
    })),
    isPastShipment: [EXPIRED, COMPLETED].includes(data.lifecycle_status),
    scheduledActionsStatuses: data.scheduled_actions.map((scheduledAction) => ({
      scheduledActionStatus: EVENT_STATUS_DISPLAY_MAP[scheduledAction.run_on],
      scheduledActionName: scheduledAction.action_name,
      notificationStatus: getNotificationStatusMapDisplay(
        scheduledAction.notification_status,
      ),
    })),
    ...(data.routing_results && {
      routeName: data.routing_results.name,
      configId: data.routing_results.config.id,
      version: data.routing_results.config.version,
      routeLink: formatHash(data.routing_results.name),
    }),
  };
};
