<template>
  <EmptyPage
    v-if="showEmptyPage"
    :empty-config="emptyMessageConfig"
    @button-primary-action="navigateToView('CreateEmailNotification')"
    @button-secondary-action="showMappingPage()"
  />
  <MainPage
    v-else
    :error-message="errorMessage"
    :loading="isLoading"
    data-cy="list-actions-page"
    heading="Notifications"
    tagline="Create email notifications and integrate them with your branded templates."
    @wipe-error="wipeError"
  >
    <template #actionsBar>
      <Button
        label="Dynamic Content"
        class="p-button p-button-outlined"
        icon="pi pi-bolt"
        @click="showMappingPage"
      />
      <Button
        label="New Email Notification"
        class="p-button"
        icon="pi pi-plus"
        data-cy="create-action-link"
        @click="navigateToView('CreateEmailNotification')"
      />
    </template>
    <template #body>
      <div class="card shadow">
        <div class="card-body">
          <TabView>
            <template #TabPanels>
              <TabPanel>
                <template #header>
                  <div class="tab-header">
                    <i class="pi pi-envelope" />
                    <span>Emails</span>
                  </div>
                </template>
                <NotificationsTable
                  v-model:actionCheckboxes="actionCheckboxes"
                />
              </TabPanel>
            </template>
          </TabView>
        </div>
      </div>
    </template>
  </MainPage>
</template>
<script setup>
import Button from "primevue/button";
import TabView from "@/components/TabView";
import TabPanel from "primevue/tabpanel";
import { computed, onMounted, ref } from "vue";
import { Navigator } from "@/services/utils/navigator";
import NotificationsTable from "@/components/notifications/NotificationsTable";
import EmptyPage from "@/components/EmptyPage";
import DataMappingPage from "@/components/template-data-mapping/DataMappingPage.vue";
import { useDynamicSidebar } from "@/store/dynamicSidebar";
import { useQueryCompanyState } from "@/queries/queriesCompany";
import { useNotificationsEmptyState } from "@/components/notifications/helpers/emptyStateConfig";
import NotificationsApiRequests from "@/components/notifications/helpers/apiRequests";

const isLoading = ref(true);
const errorMessage = ref("");
const wipeError = () => {
  errorMessage.value = "";
};

const sidebar = useDynamicSidebar();
const showMappingPage = () => {
  sidebar.open(DataMappingPage);
};

const actionCheckboxes = ref([]);
onMounted(async () => {
  isLoading.value = true;
  try {
    actionCheckboxes.value = await NotificationsApiRequests.getActions();
  } catch {
    errorMessage.value = "Failed to load.";
  }
  isLoading.value = false;
});

const { data, isFetched } = useQueryCompanyState();
const { emptyConfig, initialConfig } = useNotificationsEmptyState();
const isEmpty = computed(() => !actionCheckboxes.value.length);
const showEmptyPage = computed(() => {
  return !isLoading.value && isEmpty.value;
});
const emptyMessageConfig = computed(() => {
  if (isEmpty.value && isFetched.value) {
    if (data.value.hasCreatedAction) {
      return emptyConfig;
    } else {
      return initialConfig;
    }
  }
  return {};
});
const { navigateToView } = Navigator();
</script>
<style scoped>
.card-body {
  padding: 32px;
}
.tab-header {
  display: flex;
  gap: 8px;
  align-items: center;
}
</style>
