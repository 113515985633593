<template>
  <div v-show="!loading">
    <form @submit="onSubmit">
      <div class="form" data-cy="template-mapping-form">
        <FormInputText
          name="templateMappingName"
          placeholder="Data mapping name here..."
          label="Data mapping name:"
          data-cy="template-mapping-name"
        />
        <div class="p-fieldset">
          <span
            >Map your required attributes to use within your templates:</span
          >
          <div class="rows">
            <DataMappingRow
              v-for="(mappingStatement, index) in templateMappings"
              :id="`data-mapping-row-${index}`"
              :key="mappingStatement.key"
              :index="index"
              :mapping-statement="mappingStatement.value"
              :with-delete-option="areRowsDeletable"
              :attribute-options="disableUsedAttributes"
              @delete-mapping="deleteStatement"
            />
          </div>
          <Button
            class="p-button p-button-tertiary-outlined"
            data-cy="add-row-btn"
            @click="addStatement"
            ><i class="pi pi-plus" /> Add Attribute
          </Button>
        </div>
        <div class="d-flex justify-content-end action-buttons">
          <Button
            class="p-button p-button-text"
            data-cy="cancel-btn"
            label="Cancel"
            @click="onCloseModal"
          />
          <Button class="p-button" data-cy="submit-btn" type="submit">
            {{ submitButtonText }}
          </Button>
        </div>
      </div>
    </form>
  </div>
  <div v-show="loading">
    <ProgressSpinner />
  </div>
</template>

<script setup>
import { DIALOG_RESULTS, EDIT, CREATE } from "@/constants";
import DataMappingRow from "@/components/template-data-mapping/DataMappingRow";
import Button from "primevue/button";
import { computed, inject, onMounted, ref } from "vue";
import FormInputText from "@/components/FormInputText.vue";
import { toTypedSchema } from "@vee-validate/zod";
import { useFieldArray, useForm } from "vee-validate";
import { useTemplateMappingSchema } from "@/services/validation-form-schemas/templateMappingSchema";
import ProgressSpinner from "primevue/progressspinner";
import { useTemplateMappingsStore } from "@/components//template-data-mapping/stores/templateMappings.js";
import { useTemplateMappingsConfirmDialog } from "@/components/template-data-mapping/helpers/confirmModalConfigs";
import { useCompanyStateManagement } from "@/queries/queriesCompany";
import TemplateMappingsApiRequests from "@/components/template-data-mapping/helpers/apiRequests";

const dialogRef = inject("dialogRef");
const { dataMappingId } = dialogRef.value.data;

const shipmentAttributes = ref([]);
const getShipmentAttributes = async () => {
  shipmentAttributes.value =
    await TemplateMappingsApiRequests.getShipmentAttributes();
};

const validationSchema = toTypedSchema(useTemplateMappingSchema());
const { handleSubmit, resetForm, meta } = useForm({
  validationSchema,
  initialValues: {
    templateMappingName: "",
    templateMappings: [{ attributeId: "", handlebar: "" }],
  },
});
const {
  remove: removeTemplateMapping,
  push: addTemplateMapping,
  fields: templateMappings,
} = useFieldArray("templateMappings");

const { invalidateCompanyState } = useCompanyStateManagement();
const onSubmit = handleSubmit(async (values) => {
  dataMappingId ? await updateMapping(values) : await createMapping(values);
  await invalidateCompanyState();
});

const submitButtonText = computed(() => {
  return dataMappingId ? "Update Data Mapping" : "Create Data Mapping";
});

const getMappingData = async () => {
  const { name, handlebarMappings } =
    await TemplateMappingsApiRequests.getDataMappingById(dataMappingId);
  resetForm({
    values: { templateMappingName: name, templateMappings: handlebarMappings },
  });
};

const addStatement = () => {
  addTemplateMapping({
    attributeId: "",
    handlebar: "",
  });
};

const deleteStatement = (index) => {
  removeTemplateMapping(index);
};

const areRowsDeletable = computed(() => {
  return templateMappings.value.length > 1;
});

const disableUsedAttributes = computed(() => {
  const usedAttributes = templateMappings.value.map(
    (statement) => statement.value.attributeId,
  );
  return shipmentAttributes.value?.map((attribute) => ({
    ...attribute,
    "option-disabled": usedAttributes.includes(attribute.id),
  }));
});

const store = useTemplateMappingsStore();
const { createTemplateMapping, updateTemplateMapping } = store;

const createMapping = async (values) => {
  const result = await createTemplateMapping(
    values.templateMappingName,
    values.templateMappings,
  );
  if (result !== DIALOG_RESULTS.FAILED) dialogRef.value.close(result);
};

const updateMapping = async (values) => {
  await updateTemplateMapping(
    dataMappingId,
    values.templateMappingName,
    values.templateMappings,
  );
  dialogRef.value.close(DIALOG_RESULTS.UPDATED);
};

const loading = ref(false);
onMounted(async () => {
  loading.value = true;
  await getShipmentAttributes();
  if (dataMappingId) {
    await getMappingData();
  }
  loading.value = false;
});

const { confirmTemplateDataCancelEditing } = useTemplateMappingsConfirmDialog();

const onCloseModal = () => {
  const cancelAction = dataMappingId ? EDIT : CREATE;

  if (meta.value.dirty) {
    confirmTemplateDataCancelEditing(cancelAction, {
      callback: () => dialogRef.value.close(DIALOG_RESULTS.CANCELLED),
    });
  } else {
    dialogRef.value.close(DIALOG_RESULTS.CANCELLED);
  }
};
</script>
<style scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.rows {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
}

.cancel-button {
  background: transparent;
  border: none;
  height: 40px;
}

.action-buttons {
  gap: 8px;
}
</style>
