<template>
  <EmptyPage
    v-if="showEmptyPage"
    :empty-config="emptyMessageConfig"
    @button-primary-action="createProfile()"
    @button-secondary-action="backToShipments()"
  />
  <ProgressSpinner v-else-if="isLoading" />
  <MainPage
    v-else
    :loading="isLoading"
    heading="CSV Import Profiles"
    tagline="Map your CSV rows to required fields by creating a CSV import profile."
  >
    <template #actionsBar>
      <Button class="p-button header-btn back-button" @click="backToShipments">
        <i class="pi pi-angle-left" /> Back to Shipments
      </Button>
      <Button class="p-button" @click="createProfile">
        <i class="pi pi-plus" /> New Import Profile
      </Button>
    </template>
    <template #body>
      <div class="card card-body profile-cards-list">
        <ImportProfileListCard
          v-for="profile in profiles"
          :key="profile.id"
          :profile-name="profile.name"
          :profile-id="profile.id"
          data-cy="csv-profile-card"
          @on-profile-delete="fetchCsvProfiles"
          @click="openCsvProfileDetails(profile.name, profile.id)"
        />
      </div>
    </template>
  </MainPage>
</template>
<script setup>
import MainPage from "@/components/MainPage.vue";
import Button from "primevue/button";
import { computed, onMounted, ref, watch } from "vue";
import ImportProfileListCard from "@/components/csv-import/ImportProfileListCard.vue";
import EmptyPage from "@/components/EmptyPage.vue";
import { Navigator } from "@/services/utils/navigator";
import { useNotificationEmptyState } from "@/services/empty-states-config/importCsvProfileEmptyState";
import { useDynamicSidebar } from "@/store/dynamicSidebar";
import ImportProfileDetails from "@/components/csv-import/ImportProfileDetails.vue";
import ProgressSpinner from "primevue/progressspinner";
import { useQueryCompanyState } from "@/queries/queriesCompany";
import CsvApiRequests from "@/components/csv-import/helpers/apiRequests";

const { navigateToView } = Navigator();
const backToShipments = () => {
  return navigateToView("ListShipments");
};
const createProfile = () => {
  return navigateToView("CreateCsvProfileStepName");
};

const { data, isFetched } = useQueryCompanyState();
const emptyMessageConfig = ref({});
const { emptyConfig, initialConfig } = useNotificationEmptyState();
const setupEmptyPage = async () => {
  if (isFetched.value && data.value.hasCreatedCsvProfiles) {
    emptyMessageConfig.value = emptyConfig;
  } else {
    emptyMessageConfig.value = initialConfig;
  }
};
const profiles = ref([]);
const isLoading = ref(true);
const showEmptyPage = computed(() => {
  return !isLoading.value && !profiles.value.length;
});

watch(
  () => showEmptyPage.value,
  async () => await setupEmptyPage(),
  { immediate: true },
);

const fetchCsvProfiles = async () => {
  profiles.value = await CsvApiRequests.getCsvProfiles();
};

onMounted(async () => {
  isLoading.value = true;
  await fetchCsvProfiles();
  isLoading.value = false;
});

const sidebar = useDynamicSidebar();
const openCsvProfileDetails = (profileName, profileId) => {
  sidebar.open(ImportProfileDetails, {
    profileId: profileId,
    profileName: profileName,
  });
};
</script>

<style scoped>
.back-button {
  height: 40px;
}

.card-body {
  padding: 32px;
}

.profile-cards-list {
  display: grid;
  column-gap: 16px;
  row-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-template-rows: 1fr auto;
  grid-auto-flow: row;
}
</style>
