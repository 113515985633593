<template>
  <div class="secondaryMenu">
    <Menu
      id="secondary-links"
      class="w-100"
      :model="secondaryLinks"
      @click="onMenuClick"
    />
  </div>
</template>
<script setup>
import Menu from "primevue/menu";
import { ref } from "vue";
import { useToastService } from "@/services/toast/utils";
import { useAuthStore } from "@/store/authStore";
import { useRouter } from "vue-router";

const emit = defineEmits(["menuItemClicked"]);

const router = useRouter();

const authStore = useAuthStore();
const { logOut } = authStore;

const { displayErrorGenericToast } = useToastService();

const onMenuClick = (e) => {
  emit("menuItemClicked", e);
};

const secondaryLinks = ref([
  {
    label: "Help",
    icon: "pi pi-question-circle",
    url: "https://support.scurri.co.uk/hc/en-us/sections/15277269219346-How-Tos",
    target: "_blank",
  },
  {
    label: "Settings",
    icon: "pi pi-cog",
    to: { name: "Settings" },
  },
  {
    label: "Sign Out",
    icon: "pi pi-sign-out",
    command: async () => {
      const { error } = await logOut();
      if (!error) await router.push({ path: "/pre-login" });
      else displayErrorGenericToast();
    },
  },
]);
</script>
<style scoped lang="scss">
@import "@/scss/colors.scss";

.p-menuitem .p-menuitem-link {
  box-shadow: none;
}

:deep(.p-menu) {
  background: $dark-blue;
  border: unset;
  padding: 0;

  .p-menuitem .p-menuitem-link {
    padding: 13px 16px;
    color: $mid-grey;

    .p-menuitem-text {
      font-weight: 600 !important;
    }

    .p-menu
      .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
      > .p-menuitem-content
      &:hover {
      background-color: $mid-blue !important;
      text-decoration: none;
      &:not(:focus) {
        .p-menuitem-text,
        .p-menuitem-icon,
        .p-panelmenu-icon {
          color: $light-grey !important;
        }
      }
    }
  }
}

:deep(
    .p-menu
      .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
      > .p-menuitem-content
  ) {
  background: $dark-blue !important;
}

:deep(
    .p-menu
      .p-menuitem:not(.p-highlight):not(.p-disabled)
      > .p-menuitem-content:hover
  ) {
  background: $mid-blue !important;
}

:deep(.router-link-active) {
  background: $mid-blue;
  border-radius: 6px;

  > .p-menuitem-text {
    color: $white !important;
  }

  .pi {
    color: $green !important;
  }
}

:deep(.p-menuitem-text),
:deep(.p-menuitem-icon) {
  color: $mid-grey !important;
  margin-right: 8px;
}

.secondaryMenu {
  border-top: 1px solid #1f2a4e;
  padding: 8px;
}

:deep(.p-menu-list) {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
</style>
