import * as Sentry from "@sentry/vue";
import { isEnvVariableTrue, useDomainMapping } from "@/services/utils/utils";

export const shouldEnableSentry = () => {
  return isEnvVariableTrue(process.env.VUE_APP_IS_SENTRY_INCLUDED);
};

export const initSentry = (app, router) => {
  if (!shouldEnableSentry()) return;

  const { mappedEnvToDomain } = useDomainMapping();

  Sentry.init({
    app,
    dsn: "https://513348cd5b954ee7809ecf37a840f2ee@o13254.ingest.sentry.io/4505425427300352",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    environment: mappedEnvToDomain,
    tracesSampleRate: 0.4,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
};

export const captureSentryException = (error) => {
  if (!shouldEnableSentry()) return;

  Sentry.captureException(error);
};
