<template>
  <MainPage
    :error-message="errorMessage"
    data-cy="shipments-list-page"
    main-class="list-shipments"
    :loading="loading"
    @wipe-error="wipeError"
  >
    <template #headingBar>
      <h3 class="m-0 shipments-list-heading">
        <span>Active Shipments</span><span>{{ shipmentsCountDisplay }}</span>
      </h3>
    </template>
    <template #actionsBar>
      <ListShipmentsActionBar
        :display-csv-upload="isCsvImportEnabled()"
        :sub-menu-items="subMenuItems"
        :filter-query="filterQuery"
        @refresh-shipments="reload"
      />
    </template>
    <template #body>
      <ListShipmentsFilterTabs
        :key="keyFiltersTabs"
        :status="filterQuery.status"
        :total-counts="totalCounts"
        @toggle-status="toggleStatus"
      />
      <div v-if="isContentLoaded" class="card shadow">
        <div class="card-body">
          <ListShipmentsTable
            :key="listShipmentsKey"
            v-model:status="filterQuery.status"
            :tracking-number="filterQuery.tracking_number"
            :lifecycle-status="ACTIVE"
            @fetching-failed="onFetchingFailed"
            @reevaluate-success="reload"
            @delete-import-error-success="reload"
            @delete-import-error-failed="onDeleteImportErrorFailed"
            @reevaluate-failed="onReEvaluateShipmentsFailed"
          />
        </div>
      </div>
    </template>
  </MainPage>
</template>

<script setup>
import ListShipmentsFilterTabs from "./ListShipmentsFilterTabs.vue";
import ListShipmentsTable from "./ListShipmentsTable.vue";
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useCsvImportDialog } from "@/components/csv-import/helpers/modalConfigs";
import ListShipmentsActionBar from "@/components/shipments/ListShipmentsActionBar";
import { UrlQuery } from "@/services/utils/urlQuery";
import { ACTIVE } from "@/constants";
import ShipmentsApiRequests from "@/components/shipments/helpers/apiRequests";
import { formatNumberWithComma } from "@/services/utils/utils";
import { useFeatureFlagManager } from "@/features";

const { reduceQueryParam, addQueryParam, updateUrlQuery } = UrlQuery();

const { isCsvImportEnabled } = useFeatureFlagManager();

const listShipmentsKey = ref(0);

const totalCounts = ref({
  error: 0,
  noRouteAssigned: 0,
  routeAssigned: 0,
  totalCombined: 0,
});
const getTotalCounts = async () => {
  ({ totalCounts: totalCounts.value } =
    await ShipmentsApiRequests.getShipmentsStats());
};

const shipmentsCountDisplay = computed(() => {
  const shipmentsCount = totalCounts.value.totalCombined;
  return shipmentsCount ? `(${formatNumberWithComma(shipmentsCount)})` : "";
});

const loading = ref(false);
const errorMessage = ref("");
const isContentLoaded = computed(() => {
  return !loading.value;
});
const wipeError = () => {
  errorMessage.value = null;
};
const reload = async () => {
  loading.value = true;
  try {
    await getTotalCounts();
    keyFiltersTabs.value++;
    listShipmentsKey.value++;
    wipeError();
  } catch {
    errorMessage.value =
      "An error has occurred. Please try again later or contact support if the issue persists.";
  }
  loading.value = false;
};
const onFetchingFailed = () => {
  errorMessage.value = "Fetching shipments has failed.";
};
const onReEvaluateShipmentsFailed = () => {
  errorMessage.value =
    "Shipment recheck failed. We encountered an error while trying to recheck your shipments. Please try again later.";
};

const onDeleteImportErrorFailed = () => {
  errorMessage.value = "Deletion has failed.";
};

const router = useRouter();
const route = useRoute();
const filterQuery = ref(route.query);
const setQuery = (query = null) => {
  filterQuery.value = query;
};

const replaceStatus = (value) => {
  addQueryParam(filterQuery.value, "status", value);
};

const toggleStatus = (status, isActive) => {
  if (filterQuery.value.status) {
    isActive
      ? reduceQueryParam(filterQuery.value, "status")
      : replaceStatus(status);
  } else {
    updateUrlQuery({ status });
  }
};

const keyFiltersTabs = ref(0);

const openCsvProfileImport = () => {
  router.push({ name: "ListCsvProfiles" });
};
const { openDialogImportShipmentDialog } = useCsvImportDialog();
const openCsvImportModal = () => {
  openDialogImportShipmentDialog({});
};
const subMenuItems = computed(() => {
  return [
    {
      label: "Import by CSV",
      icon: "pi pi-upload",
      command: () => openCsvImportModal(),
    },
    {
      label: "Import Profiles",
      icon: "pi pi-cog",
      command: () => openCsvProfileImport(),
    },
  ];
});

const setupQueryWatcher = () => {
  watch(
    () => route.query,
    (toQuery) => {
      setQuery(toQuery);
      reload();
    },
  );
};
const setupShipmentsPage = async () => {
  setupQueryWatcher();
  setQuery(route.query);
  await reload();
};
setupShipmentsPage();
</script>
<style scoped lang="scss">
:deep(.p-message.p-message-error) {
  border-radius: 4px;
  border: 1px solid #fccad5;
  background: #fff2f5;

  & .error-message {
    color: #73081e;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
