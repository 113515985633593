<template>
  <NoRouteAssignedSection
    v-if="displayNoRouteAssignedSection"
    description="No campaign could be assigned as there were no matching segment filters."
    @confirm-re-evaluate="confirmReevaluateShipments"
  />
  <ListShipmentsImportErrorSection
    v-else-if="displayImportErrorSection"
    @bulk-delete-import-error="bulkDeleteImportError"
  />
  <div class="shipments-table" data-cy="shipments-table">
    <DataTable
      ref="dt"
      v-model:filters="shipmentsTableFilters"
      :value="shipments"
      data-key="id"
      :lazy="true"
      :total-records="totalRecords"
      :paginator="!isEmpty"
      paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      filter-display="menu"
      :global-filter-fields="['status', 'latest_event']"
      :rows="rows"
      :row-hover="true"
      :rows-per-page-options="[10, 25, 50]"
      removable-sort
      current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
      responsive-layout="scroll"
      @row-click="onRowClick($event)"
      @page="onPage($event)"
      @filter="onFilter($event)"
      @sort="onSort($event)"
    >
      <template #empty>
        <div class="empty-shipments">
          <ProgressSpinner v-show="loading" />
          <EmptyShipmentsMessage
            v-show="!loading"
            :tracking-status="selectedTrackingStatus"
            :route-status="selectedRouteStatus"
            :tracking-number="trackingNumber"
          />
        </div>
      </template>
      <!-- Tracking Number -->
      <Column
        key="tracking_number"
        field="tracking_number"
        header="Tracking Number"
        class="column-tracking-number"
      >
        <template #body="{ data }">
          <span class="tracking-number">{{
            data.tracking_number ? data.tracking_number : "N/A"
          }}</span>
        </template>
      </Column>
      <!-- Route Status Active Only-->
      <Column
        v-if="displayActiveCol"
        key="status"
        field="status"
        header="Status"
        class="column-status"
        :filter-menu-style="{ width: '14rem' }"
        :show-filter-match-modes="false"
        filter-field="status"
      >
        <template #body="{ data }">
          <ListShipmentsTableStatus :status="data.status" />
        </template>
        <template #filter="{ filterModel }">
          <Dropdown
            v-model="filterModel.value"
            :options="ROUTE_STATUS_FILTER_CHOICES"
            placeholder="Select Status"
            class="p-column-filter"
            :show-clear="false"
            data-cy="status-filters"
          >
            <template #option="slotProps">
              <span
                :class="
                  'shipment-status-opt ' + convertToClass(slotProps.option)
                "
                >{{ slotProps.option }}</span
              >
            </template>
            <template #value="slotProps">
              <span
                v-if="slotProps.value"
                :class="
                  'shipment-status-opt ' + convertToClass(slotProps.value)
                "
                >{{ slotProps.value }}</span
              >
              <span v-else>{{ slotProps.placeholder }}</span>
            </template>
          </Dropdown>
        </template>
      </Column>
      <!-- Lifecycle Status Past Only-->
      <Column
        v-if="displayPastCol"
        key="lifecycle_status"
        field="lifecycle_status"
        header="Status"
        class="col-lifecycle-status"
        style="width: 4.5rem"
      >
        <template #body="{ data }">
          <ListShipmentsTableLifecycleStatus
            :lifecycle-status="data.lifecycle_status"
          />
        </template>
      </Column>
      <!-- Carrier Past Only -->
      <Column
        v-if="displayPastCol"
        key="carrier"
        field="carrier"
        header="Carrier"
      >
        <template #body="{ data }">
          <span>{{ data.carrier }}</span>
        </template>
      </Column>
      <!-- Created -->
      <Column
        key="created"
        field="created"
        header="Created"
        class="column-created"
        sortable
      >
        <template #body="{ data }">
          <span>{{ data.created }}</span>
        </template>
      </Column>
      <!-- Last Updated  -->
      <Column
        key="updated"
        field="updated"
        :header="lastUpdatedColHeader"
        sortable
      >
        <template #body="{ data }">
          <span>{{ data.updated }}</span>
        </template>
      </Column>
      <!-- Latest Event -->
      <Column
        key="latest_event"
        field="latest_event"
        :header="latestEventColHeader"
        :show-filter-match-modes="false"
        :filter-menu-style="{ width: '14rem' }"
        filter-field="latest_event"
      >
        <template #body="{ data }">
          <StatusBadge size="small" :status="data.latest_event">{{
            data.latest_event
          }}</StatusBadge>
        </template>
        <template #filter="{ filterModel }">
          <Dropdown
            v-model="filterModel.value"
            :options="TRACKING_EVENT_FILTER_CHOICES"
            placeholder="Select Event"
            class="p-column-filter"
            :show-clear="false"
          >
            <template #option="slotProps">
              <StatusBadge size="small" :status="slotProps.option">{{
                slotProps.option
              }}</StatusBadge>
            </template>
            <template #value="slotProps">
              <StatusBadge
                v-if="slotProps.value"
                size="small"
                :status="slotProps.value"
              >
                {{ slotProps.value }}
              </StatusBadge>
              <span v-else>{{ slotProps.placeholder }}</span>
            </template>
          </Dropdown>
        </template>
      </Column>
      <!-- Notification Progress -->
      <Column
        key="scheduled_actions_counts"
        field="scheduled_actions_counts"
        :header="notificationsColHeader"
      >
        <template #body="{ data }">
          <ListShipmentsTableNotifications
            :hide-pending="isPastShipment"
            :notifications-data="data.scheduled_actions_counts_display"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup>
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import {
  ERROR_STATUS,
  EVENT_STATUS_DISPLAY_MAP,
  ROUTE_STATUS_FILTER_CHOICES,
  ROUTING_FAILED_STATUS,
  STATUS_DISPLAY_MAP,
  TRACKING_EVENT_FILTER_CHOICES,
} from "@/constants";
import StatusBadge from "@/components/StatusBadge.vue";
import { FilterMatchMode } from "primevue/api";
import ShipmentDetails from "@/components/shipments/ShipmentDetails";
import { computed, onMounted, ref, toRefs, watch } from "vue";
import { useOpenInNewTab } from "@/components/shipments/helpers/utils";
import { convertToClass } from "@/services/utils/utils";
import ListShipmentsTableStatus from "@/components/shipments/ListShipmentsTableStatus";
import { useDynamicSidebar } from "@/store/dynamicSidebar";
import { useShipmentsToastService } from "@/components/shipments/helpers/toastConfigs";
import EmptyShipmentsMessage from "@/components/shipments/EmptyShipmentsMessage";
import ProgressSpinner from "primevue/progressspinner";
import ListShipmentsImportErrorSection from "@/components/shipments/ListShipmentsImportErrorSection";
import ListShipmentsTableLifecycleStatus from "@/components/shipments/ListShipmentsTableLifecycleStatus";
import ListShipmentsTableNotifications from "@/components/shipments/ListShipmentsTableNotifications";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";
import {
  shipmentRerouteErrorMap,
  shipmentRerouteErrorMapper,
} from "@/components/shipments/helpers/errors";
import NoRouteAssignedSection from "@/components/shipments/NoRouteAssignedSection.vue";
import { useShipmentsConfirmDialog } from "@/components/shipments/helpers/confirmModalConfigs";
import ShipmentsApiRequests from "@/components/shipments/helpers/apiRequests";

const props = defineProps({
  status: {
    default: "",
    type: String,
  },
  trackingNumber: {
    default: "",
    type: String,
  },
  lifecycleStatus: {
    default: "",
    type: String,
  },
  rows: {
    default: 10,
    type: Number,
  },
  isPastShipment: {
    default: false,
    type: Boolean,
  },
  latestEvent: {
    default: "",
    type: String,
  },
});
const emit = defineEmits([
  "update:status",
  "update:shipments",
  "fetching-failed",
  "reevaluate-success",
  "reevaluate-failed",
  "delete-import-error-success",
  "delete-import-error-failed",
  "fetching-success",
  "update:latest-event",
]);
const { status, rows, trackingNumber, lifecycleStatus, isPastShipment } =
  toRefs(props);
const dt = ref();
const lazyParams = ref({});
const loading = ref(true);
const totalRecords = ref(0);
const shipments = ref([]);

const {
  displayReevaluationStartedToast,
  displayDeleteImportErrorStartedToast,
} = useShipmentsToastService();

const isEmpty = computed(() => {
  return shipments.value.length === 0;
});

onMounted(async () => {
  lazyParams.value = {
    first: 0,
    rows: dt.value.rows,
    sortField: null,
    sortOrder: null,
    filters: shipmentsTableFilters,
  };
  await loadLazyData();
});

const loadLazyData = async () => {
  try {
    const query = {
      offset: lazyParams.value.first,
      limit: lazyParams.value.rows,
    };
    if (trackingNumber.value !== "") {
      query.tracking_number = trackingNumber.value;
    }
    if (lazyParams.value.filters.status.value !== null) {
      query.status = Object.keys(STATUS_DISPLAY_MAP).find(
        (key) =>
          STATUS_DISPLAY_MAP[key] === lazyParams.value.filters.status.value,
      );
    }
    if (lazyParams.value.filters.latest_event.value !== null) {
      query.latest_event = Object.keys(EVENT_STATUS_DISPLAY_MAP).find(
        (key) =>
          EVENT_STATUS_DISPLAY_MAP[key] ===
          lazyParams.value.filters.latest_event.value,
      );
    }
    if (lazyParams.value.filters.lifecycle_status.value !== null) {
      query.lifecycle_status = lazyParams.value.filters.lifecycle_status.value;
    }
    const sortField = lazyParams.value.sortField;
    const sortOrder = lazyParams.value.sortOrder;
    if (sortField && sortOrder) {
      query.order_by = sortOrder > 0 ? `-${sortField}` : sortField;
    }
    loading.value = true;
    shipments.value = [];
    const data = await ShipmentsApiRequests.fetchShipmentForListPage(query);
    shipments.value = data.shipments;
    if (isPastShipment.value && !shipments.value.length) {
      emit("update:latest-event", lazyParams.value.filters.latest_event.value);
    }
    emit("fetching-success", shipments.value);
    totalRecords.value = data.count;
    loading.value = false;
  } catch {
    emit("fetching-failed");
    loading.value = false;
  }
};

const { confirmReEvaluateShipments } = useShipmentsConfirmDialog();
const confirmReevaluateShipments = () =>
  confirmReEvaluateShipments({
    callback: async () => await reEvaluateShipments(),
  });

const { makeRequest } = useConfigurationRequests();

const reEvaluateShipments = async () => {
  await makeRequest({
    endpoint: async () => await ShipmentsApiRequests.reRouteShipments(),
    onSuccess: () => {
      displayReevaluationStartedToast();
      emit("reevaluate-success");
    },
    errorMapper: (errors) =>
      shipmentRerouteErrorMapper(errors, shipmentRerouteErrorMap, () =>
        emit("reevaluate-failed"),
      ),
  });
};

const bulkDeleteImportError = async () => {
  try {
    await ShipmentsApiRequests.bulkDeleteImportError();
    emit("delete-import-error-success");
    displayDeleteImportErrorStartedToast();
    await loadLazyData();
  } catch (error) {
    emit("delete-import-error-failed");
  }
};

const shipmentsTableFilters = ref({
  status: {
    value: STATUS_DISPLAY_MAP[status.value] || null,
    matchMode: FilterMatchMode.EQUALS,
  },
  latest_event: {
    value: null,
    matchMode: FilterMatchMode.EQUALS,
  },
  lifecycle_status: {
    value: lifecycleStatus.value || null,
    matchMode: FilterMatchMode.EQUALS,
  },
});

const sidebar = useDynamicSidebar();
const showShipmentDetails = (e) => {
  sidebar.open(ShipmentDetails, { shipmentId: e.data.id });
};

const onPage = (event) => {
  lazyParams.value = event;
  loadLazyData();
};

const onSort = (event) => {
  lazyParams.value = event;
  loadLazyData();
};
const onFilter = async () => {
  lazyParams.value.filters = shipmentsTableFilters.value;
  const toEmit = Object.keys(STATUS_DISPLAY_MAP).find(
    (key) =>
      STATUS_DISPLAY_MAP[key] === shipmentsTableFilters.value.status.value,
  );
  emit("update:status", toEmit);
  await loadLazyData();
};

const { openInNewTab } = useOpenInNewTab();
const onRowClick = (e) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: "shipment_details_page_open" });

  if (e.originalEvent.ctrlKey || e.originalEvent.metaKey)
    openInNewTab(e.data.id);
  else showShipmentDetails(e);
};

watch(status, async (newStatus) => {
  shipmentsTableFilters.value.status.value =
    STATUS_DISPLAY_MAP[newStatus] || null;
  await loadLazyData();
});

watch(lifecycleStatus, async (newStatus) => {
  shipmentsTableFilters.value.lifecycle_status.value = newStatus;
  await loadLazyData();
});

watch(trackingNumber, async () => {
  shipmentsTableFilters.value.status.value = null;
  shipmentsTableFilters.value.latest_event.value = null;
  shipmentsTableFilters.value.lifecycle_status.value = null;
  await loadLazyData();
});

const isOnNoRouteAssignedSection = computed(() => {
  return (
    shipmentsTableFilters.value.status.value ===
    STATUS_DISPLAY_MAP[ROUTING_FAILED_STATUS]
  );
});

const isOnImportErrorSection = computed(() => {
  return (
    shipmentsTableFilters.value.status.value ===
    STATUS_DISPLAY_MAP[ERROR_STATUS]
  );
});

const displayNoRouteAssignedSection = computed(() => {
  return isOnNoRouteAssignedSection.value && shipments.value?.length;
});

const displayImportErrorSection = computed(() => {
  return isOnImportErrorSection.value && shipments.value?.length;
});

const selectedRouteStatus = computed(() => {
  return shipmentsTableFilters.value.status.value;
});

const selectedTrackingStatus = computed(() => {
  return lazyParams.value.filters?.latest_event.value;
});

const displayActiveCol = computed(() => {
  return !isPastShipment.value;
});

const displayPastCol = computed(() => {
  return isPastShipment.value;
});

const lastUpdatedColHeader = computed(() => {
  return isPastShipment.value ? "Last Update" : "Latest Update";
});

const latestEventColHeader = computed(() => {
  return isPastShipment.value ? "Last Event" : "Latest Event";
});

const notificationsColHeader = computed(() => {
  return "Notifications";
});
</script>
<style scoped lang="scss">
:deep(.p-datatable-emptymessage td) {
  padding: 0 !important;
}
.empty-shipments {
  background: #fff;
  cursor: auto;
  &:hover {
    background: #fff;
  }
}
:deep(.column-tracking-number) {
  min-width: 264px;
}
:deep(.column-status) {
  width: 112px;
}
:deep(.column-created) {
  min-width: 115px;
}
:deep(.column-updated) {
  min-width: 149px;
}
:deep(.column-latest-event) {
  min-width: 174px;
}
:deep(.column-notification-progress) {
  min-width: 179px;
}
:deep(.p-sortable-column) {
  transition: all 0.3s ease !important;

  .p-sortable-column-icon {
    color: #343a40 !important;
  }

  .p-column-title {
    margin-right: auto;
  }

  &:focus {
    box-shadow: none;
  }
}

:deep(.p-sortable-column.p-highlight) {
  background: #f2f5ff;
  color: #2e4799 !important;

  .p-sortable-column-icon {
    color: #2e4799 !important;
  }

  &:hover {
    background: #e5ebff;
  }
}
</style>
