<template>
  <div class="iframe-container">
    <div v-if="loading" class="spinner-container">
      <ProgressSpinner />
    </div>
    <iframe
      v-else
      :src="dashboardUrl"
      class="iframe-container__iframe"
      data-cy="analytics-iframe"
    />
  </div>
</template>

<script setup>
import { ref, toRefs, watch } from "vue";
import AnalyticsApiRequests from "@/components/analytics/helpers/analyticsRequests";
import ProgressSpinner from "primevue/progressspinner";

const props = defineProps({
  dashboardSlug: {
    type: String,
    required: true,
  },
});

const { dashboardSlug } = toRefs(props);
const loading = ref(true);

const dashboardUrl = ref("");

const getDashboardDetails = async () => {
  loading.value = true;
  const { embedUrl } = await AnalyticsApiRequests.getDashboardDetails(
    dashboardSlug.value,
  );
  dashboardUrl.value = embedUrl;
  loading.value = false;
};

watch(
  dashboardSlug,
  async () => {
    await getDashboardDetails();
  },
  { immediate: true },
);
</script>
<style scoped lang="scss">
.iframe-container__iframe {
  width: 100%;
  height: 100%;
}

.iframe-container {
  width: 100%;
  height: 100%;
  padding: 32px 48px 48px 48px;
}

.spinner-container {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & > :deep(.p-progress-spinner) {
    top: unset;
    margin: unset;
    left: unset;
  }
}
</style>
