<template>
  <MainPage
    :error-message="errorMessage"
    :loading="isLoading"
    data-cy="list-brands-page"
    :heading="mainPageConfig.heading"
    :tagline="mainPageConfig.tagline"
    :show-footer="false"
    @wipe-error="wipeError"
  >
    <template v-if="!isEmpty" #actionsBar>
      <Button
        label="New Brand"
        class="p-button"
        icon="pi pi-plus"
        data-cy="create-brand-link"
        @click="openDialog"
      ></Button>
    </template>
    <template #body>
      <div class="card shadow">
        <div class="card-body" data-cy="list-brands">
          <EmptyPage
            v-if="isEmpty"
            :empty-config="emptyMessageConfig"
            @button-primary-action="openDialog"
          />
          <BrandTable v-model:brands="brands" />
        </div>
      </div>
    </template>
  </MainPage>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import Button from "primevue/button";
import BrandTable from "@/components/brands/BrandTable";
import { useBrandStore } from "@/components/brands/stores/brand";
import { storeToRefs } from "pinia";
import MainPage from "@/components/MainPage.vue";
import { useQueryCompanyState } from "@/queries/queriesCompany";
import { useBrandsEmptyState } from "@/components/brands/helpers/emptyStateConfig";
import EmptyPage from "@/components/EmptyPage.vue";
const isLoading = ref(true);
const errorMessage = ref(null);

const store = useBrandStore();
const { getBrands } = store;
const { brands } = storeToRefs(store);

const mainPageConfig = computed(() => {
  return {
    heading: isEmpty.value ? "" : "Branding",
    tagline: isEmpty.value ? "" : brandsTagline,
  };
});

const openDialog = () => {
  // TODO: Implement create brand
  console.log("opening create brand form....");
};

const getAllBrands = async () => {
  isLoading.value = true;
  try {
    await getBrands();
  } catch {
    errorMessage.value = "Failed to load.";
  }
  isLoading.value = false;
};

const wipeError = () => {
  errorMessage.value = null;
};

const { emptyConfig, initialConfig, brandsTagline } = useBrandsEmptyState();

const isEmpty = computed(() => !isLoading.value && !brands.value.length);

const { data, isFetched } = useQueryCompanyState();
const emptyMessageConfig = computed(() => {
  if (isEmpty.value && isFetched.value) {
    if (data.value.hasCreatedBrand) {
      return emptyConfig;
    } else {
      return initialConfig;
    }
  }
  return {};
});

onMounted(async () => {
  await getAllBrands();
});
</script>
<style scoped lang="scss">
:deep(.card-body) {
  padding: 32px;
}
.pi-times {
  position: absolute;
  width: 40px;
  height: 40px;
  left: -56px;
  top: 16px;
  background: #ffffff;
  box-shadow: -4px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #cccccc;
    transition: background-color 0.3s ease;
  }
}
</style>
