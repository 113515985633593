<template>
  <div class="lifecycle-status">
    <span
      v-tooltip.top="{
        value: tooltipRef,
        id: 'ListShipmentsTableLifeCycleStatusTooltip',
      }"
      ><i :class="iconRef" data-cy="lifecycle-status-icon"
    /></span>
  </div>
</template>

<script setup>
import { ref, toRefs, watch } from "vue";
import { COMPLETED, EXPIRED } from "@/constants";

const props = defineProps({
  lifecycleStatus: {
    default: "",
    type: String,
  },
});

const { lifecycleStatus } = toRefs(props);
const lifecycleStatusMap = {
  [COMPLETED]: {
    icon: "fas fa-custom-completed-lifecycle",
    tooltip: "Shipment complete.\n" + "No further updates from the carrier.",
  },
  [EXPIRED]: {
    icon: "pi pi-calendar-times",
    tooltip:
      "Shipment expired.\n" +
      "No update received from the carrier in over 30 days.",
  },
};

const tooltipRef = ref("");
const iconRef = ref("");

const setTooltipValue = (lifecycleStatus) => {
  const { tooltip, icon } = lifecycleStatusMap[lifecycleStatus];
  tooltipRef.value = tooltip;
  iconRef.value = icon;
};

watch(
  () => lifecycleStatus,
  () => {
    if (lifecycleStatus.value in lifecycleStatusMap)
      setTooltipValue(lifecycleStatus.value);
  },
  { immediate: true },
);
</script>
<style lang="scss" scoped>
.lifecycle-status {
  text-align: center;

  .fa-custom-completed-lifecycle {
    color: #1fb256;
  }

  .pi-calendar-times {
    color: #d96341;
  }
}
</style>
