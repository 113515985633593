<template>
  <FormDropdown
    v-model="selectedConfiguration"
    placeholder="Choose email configuration"
    :options="configurations"
    name="integrationDetails.selectedConfiguration"
    label="Email configuration:"
    data-cy="action-sendgrid-config"
    @update:model-value="fetchEmailTemplates"
  />
  <div v-show="showSendgridTemplates" class="column">
    <FormDropdown
      v-model="selectedTemplate"
      placeholder="Choose email template"
      :options="templateOptions"
      name="integrationDetails.selectedTemplates"
      label="Email template:"
      data-cy="action-sendgrid-template"
      panel-class="sendgrid-template-options"
    />
  </div>
</template>
<script setup>
import { onMounted, ref, toRefs, watch } from "vue";
import FormDropdown from "@/components/FormDropdown.vue";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";
import { useNotificationToastService } from "@/services/toast/notifications";
import NotificationsApiRequests from "@/components/notifications/helpers/apiRequests";

const props = defineProps({
  integrationDetails: {
    type: Object,
    default: () => {},
  },
});
const { integrationDetails } = toRefs(props);

const configurations = ref([]);

onMounted(async () => {
  await (async () => {
    configurations.value =
      await NotificationsApiRequests.getSendgridConfigurations();
  })();
});

const selectedConfiguration = ref("");
const selectedTemplate = ref("");

watch(
  () => integrationDetails.value,
  async (details) => {
    if (Object.keys(details).length) {
      selectedConfiguration.value = details.selectedConfiguration;
      selectedTemplate.value = details.selectedTemplate;
      await fetchEmailTemplates(selectedConfiguration.value);
    }
  },
);

const showSendgridTemplates = ref(false);
const templateOptions = ref([]);
const { makeRequest } = useConfigurationRequests();
const { displayErrorGenericToast } = useNotificationToastService();
const fetchEmailTemplates = async (configurationId) => {
  const { response, error } = await makeRequest({
    endpoint: () =>
      NotificationsApiRequests.getSendgridTemplates(configurationId),
    onFail: () => displayErrorGenericToast(),
  });
  if (!error.value) {
    templateOptions.value = response.value;
  }
  showSendgridTemplates.value = true;
};
</script>
