import { CREATE, DEV_ENV, PROD_ENV, SALES_ENV, SANDBOX_ENV } from "@/constants";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";

export const getTextWidth = (text) => {
  // Return width of text
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  context.font = getComputedStyle(document.body).font;

  return context.measureText(text).width;
};

export const mapToSentenceCase = (sentences) => {
  // If there are no sentences - there are null, empty strings - return value
  // Otherwise, make all sentence as lowercase, find first character and all
  // first characters next to .!? and make then uppercase.
  if (!sentences) return sentences;
  return sentences.toLowerCase().replace(/(^\s*\w|[.!?]\s*\w)/g, function (c) {
    return c.toUpperCase();
  });
};

export const getDiscardChangesConfig = (item, action) => {
  const itemLowerCase = item.toLowerCase();
  const itemTitleCase = itemLowerCase.replace(
    /(^\w{1})|(\s+\w{1})/g,
    (letter) => letter.toUpperCase(),
  );
  const header =
    action === CREATE ? `Discard ${itemTitleCase}?` : "Discard Changes?";
  const messageBase =
    action === CREATE
      ? "Are you sure you want to discard"
      : "Are you sure you want to discard changes to";
  const message = `${messageBase} this ${itemLowerCase}? Note that any unsaved changes will be lost.`;
  return {
    header,
    message,
    acceptLabel: "Discard",
    rejectLabel: "Cancel",
    rejectClass: "p button p-button-text",
    acceptClass: "p-button p-button-remove",
  };
};

export const useCopiedState = (copyText, copiedText) => {
  const isCopied = ref(false);
  const copyButtonText = computed(() =>
    isCopied.value ? copiedText : copyText,
  );
  const makeCopyDisabled = () => (isCopied.value = true);
  const makeCopyEnabled = () => (isCopied.value = false);

  const changeStateOnClick = () => {
    makeCopyDisabled();
    setTimeout(() => {
      makeCopyEnabled();
    }, 1000);
  };

  return { copyButtonText, changeStateOnClick, isCopied };
};

export const convertToClass = (val) => {
  return val.toLowerCase().replaceAll(" ", "-").replaceAll("_", "-");
};

export const formatNumberWithComma = (val) => {
  return Intl.NumberFormat().format(val);
};

export const isEnvVariableTrue = (envVar) => {
  const _envVar = String(envVar).toLowerCase();
  return _envVar === "true" || _envVar === "1";
};

export const useDomainMapping = () => {
  const domainMap = {
    "scurritrackplus.com": PROD_ENV,
    "sandbox.scurritrackplus.com": SANDBOX_ENV,
    "demo.scurritrackplus.com": SALES_ENV,
    "dev.scurritrackplus.com": DEV_ENV,
  };

  const currentDomain = window.location.hostname;
  const mappedEnvToDomain = domainMap[currentDomain] || "unknown";
  return { mappedEnvToDomain };
};

export const isElemUrl = (elem) => {
  try {
    new URL(elem);
    return true;
  } catch {
    return false;
  }
};

export const useEllipseChecker = (elementRef) => {
  let resizeObserver;
  let animationFrameId;
  const isEllipse = ref(false);

  const checkEllipsing = () => {
    if (animationFrameId) return;
    animationFrameId = requestAnimationFrame(() => {
      const el = elementRef.value;
      if (el) {
        const isCurrentlyEllipsis = el.scrollWidth > el.clientWidth;
        if (isEllipse.value !== isCurrentlyEllipsis) {
          isEllipse.value = isCurrentlyEllipsis;
        }

        if (isCurrentlyEllipsis && resizeObserver) {
          resizeObserver.unobserve(el);
        }
      }
      animationFrameId = null;
    });
  };

  onMounted(() => {
    const el = elementRef.value;
    if (el) {
      resizeObserver = new ResizeObserver(checkEllipsing);
      resizeObserver.observe(el);
    }
  });

  onBeforeUnmount(() => {
    if (resizeObserver) {
      resizeObserver.disconnect();
    }
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId);
    }
  });

  return { isEllipse };
};
