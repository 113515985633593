<template>
  <MainPage
    class="shipment-details"
    :error-message="errorMessage"
    data-cy="shipment-details-page"
    :show-footer="shouldFooterBeVisible()"
  >
    <template #headingBar>
      <div v-if="!isLoading" class="shipment-details-header">
        <h3>
          <span>
            Tracking No.
            <span
              class="shipment-header__tracking-num"
              data-cy="shipment-tracking-number"
            >
              {{ trackingNumber }}
            </span>
          </span>
          <span
            v-if="displayLifecycleStatus"
            :class="'lifecycle-status ' + lifecycleClass"
            data-cy="lifecycle-status-badge"
          >
            <i :class="lifecycleIcon" />{{ lifecycleStatus }}</span
          >
        </h3>
      </div>
    </template>
    <template #actionsBar>
      <div class="header-btns">
        <Button
          v-if="shouldShowViewTrackingPageButton"
          class="p-button header-btn"
          data-cy="show-portal-button"
          @click="showTrackingPage"
        >
          <i class="pi pi-eye" />
          View Tracking Page
        </Button>
        <Button
          class="p-button header-btn"
          data-cy="refresh-page"
          @click="fetchShipmentData"
        >
          <i class="pi pi-refresh" />
          Refresh
        </Button>
        <div class="header-btns--group">
          <Button
            v-if="shipmentId"
            v-tooltip.bottom="{
              value: 'Open in new tab',
              showDelay: 1000,
              hideDelay: 300,
            }"
            label="Open in new tab"
            class="p-button header-btn header-btn--icons-only"
            icon="pi pi-external-link"
            data-cy="open-new-tab"
            @click="openInNewTab(shipmentId, isPastShipment)"
          >
            <i class="pi pi-external-link" />
          </Button>
          <Button
            v-tooltip.bottom="{
              value: 'Copy URL',
              showDelay: 1000,
              hideDelay: 300,
            }"
            class="p-button header-btn header-btn--icons-only"
            data-cy="copy-url"
            :disabled="isCopied"
            @click="copyToClipboard()"
          >
            <i class="pi pi-link" />
          </Button>
        </div>
      </div>
    </template>
    <template #body>
      <div v-if="!isLoading">
        <Suspense>
          <ShipmentDetailsContainer
            :shipment-data="preparedData"
            :shipment-id="shipmentId"
            @update-data="fetchShipmentData"
          />
        </Suspense>
      </div>
      <div v-else>
        <ProgressSpinner />
      </div>
    </template>
  </MainPage>
</template>
<script setup>
import ShipmentDetailsContainer from "./ShipmentDetailsContainer";
import ProgressSpinner from "primevue/progressspinner";
import { ref, toRefs, computed, onMounted } from "vue";
import MainPage from "@/components/MainPage.vue";
import Button from "primevue/button";
import { useRoute } from "vue-router";
import { useCopiedState } from "@/services/utils/utils";
import { useOpenInNewTab } from "@/components/shipments/helpers/utils";
import { COMPLETED, EXPIRED } from "@/constants";
import { useFeatureFlagManager } from "@/features";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";
import { shipmentDetailsErrorMapper } from "@/components/shipments/helpers/errors";
import ShipmentsApiRequests from "@/components/shipments/helpers/apiRequests";
import { captureSentryException } from "@/services/sentry";

const route = useRoute();
const props = defineProps({
  shipmentId: {
    type: String,
    default: null,
  },
});
const lifecycleStatusMap = {
  [COMPLETED]: {
    icon: "fas fa-custom-completed-lifecycle",
    class: "completed",
  },
  [EXPIRED]: {
    icon: "pi pi-calendar-times",
    class: "expired",
  },
};

const { shipmentId } = toRefs(props);

const trackingNumber = ref("");
const errorMessage = ref("");
const lifecycleStatus = ref("");
const isPastShipment = ref(false);
const isLoading = ref(true);
const isPreviewEnabled = ref(false);
const preparedData = ref({});

const { isPortalPreviewEnabled } = useFeatureFlagManager();
onMounted(async () => {
  isPreviewEnabled.value = await isPortalPreviewEnabled();
  await fetchShipmentData();
});

const fetchShipmentData = async () => {
  isLoading.value = true;
  const { serializedShipment, error } =
    await ShipmentsApiRequests.getShipmentDetails(shipmentId.value);
  preparedData.value = serializedShipment;
  if (error) {
    errorMessage.value = "Data failed to load";
    captureSentryException(error);
  } else {
    trackingNumber.value = preparedData.value.trackingNumber;
    lifecycleStatus.value = preparedData.value.lifecycleStatus;
    isPastShipment.value = preparedData.value.isPastShipment;
    isLoading.value = false;
  }
};

const lifecycleIcon = computed(() => {
  return lifecycleStatusMap[lifecycleStatus.value].icon;
});
const lifecycleClass = computed(() => {
  return lifecycleStatusMap[lifecycleStatus.value].class;
});
const displayLifecycleStatus = computed(() => {
  return isPastShipment.value;
});

const { makeRequest } = useConfigurationRequests();
const showTrackingPage = async () => {
  const { response: url, error } = await makeRequest({
    endpoint: async () =>
      await ShipmentsApiRequests.getPortalUrl(shipmentId.value),
    errorMapper: (errors) => shipmentDetailsErrorMapper(errors),
  });
  if (!error.value) window.open(url.value, "_blank");
};

const { openInNewTab, getNewTabUrl } = useOpenInNewTab();
const { changeStateOnClick, isCopied } = useCopiedState("Copy Link", "Copied");

// If route is populated - it means that page is opened in new tab
const shouldFooterBeVisible = () => {
  return !!route.params.id;
};

const shouldShowViewTrackingPageButton = computed(
  () => !isPastShipment.value && isPreviewEnabled.value,
);

const copyToClipboard = () => {
  navigator.clipboard.writeText(
    new URL(getNewTabUrl(shipmentId.value), window.location.origin).href,
  );
  changeStateOnClick();
};
</script>
<style scoped lang="scss">
.pi-times:hover {
  background: #cccccc;
  transition: background-color 0.3s ease;
}

.shipment-details-header h3 {
  color: #495057;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;

  display: flex;
  gap: 8px;
}

.action-badge {
  height: 32px;
  gap: 4px;
  padding: 0 12px;
  font-size: 12px;
}

.tracking-number-title {
  font-weight: 600;
}

.tracking-number-value {
  text-transform: uppercase;
}

.header-btn--icons-only {
  padding: 0;
  width: 32px;
}

.header-btns {
  display: flex;
  gap: 12px;
}

.header-btns--group {
  display: flex;
  gap: 8px;
}

.shipment-details-header {
  .lifecycle-status {
    display: flex;
    height: 24px;
    padding: 0 6px;
    align-items: center;
    gap: 4px;
    border-radius: 24px;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.36px;
    text-transform: uppercase;
  }

  .completed {
    border: 1px solid #c7f2d7;
    background: #cff7de;
    color: #0f5428;
    .pi-check-circle {
      color: #0f5428;
    }
  }

  .expired {
    border: 1px solid #fad6cd;
    background: #ffe1d9;
    color: #66220f;
  }
}

.shipment-header__tracking-num {
  color: #1f2a4e;
  font-size: 17.5px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}
</style>
