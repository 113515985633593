/**
 * Composable components used to wrap requests used on configuration pages (Template Data Mapping, Workflow and so on).
 *
 * There are two possible wrappers - for single request (makeRequest) and for bulk requests (makeBulkRequest).
 **/

import { ref } from "vue";

export const useConfigurationRequests = () => {
  const isFinished = ref(false);
  const isLoading = ref(false);

  const loading = (loading) => {
    isLoading.value = loading;
    isFinished.value = !loading;
  };

  /**
   * @param {function} endpoint - method which makes a request
   * @param {function  | null} onInvalidAction - method raised when invalid action is returned
   * (eg. Element to be deleted is in use)
   * @param {function  | null} onInvalidInput - method raised when invalid input is returned
   * (eg. Name is already exists)
   * @param {function | null} onFail - method raised when request failed
   * @param {function | null} onSuccess - method raised when request succeed
   * @param {function | null} errorMapper - method to custom map errors received from backend
   */
  const makeRequest = async ({
    endpoint,
    onInvalidAction = null,
    onInvalidInput = null,
    onFail = null,
    onSuccess = null,
    errorMapper = null,
  }) => {
    const error = ref(null);
    const response = ref(null);

    loading(true);
    try {
      response.value = await endpoint();
      onSuccess?.();
    } catch (err) {
      const errorResponse = err.response ?? err;
      if (errorMapper) {
        errorMapper(errorResponse);
      } else {
        switch (errorResponse.data?.code) {
          case "INVALID_ACTION":
            onInvalidAction?.();
            break;
          case "INVALID_INPUT":
            onInvalidInput?.();
            break;
          default:
            onFail?.();
        }
      }
      error.value = errorResponse;
    } finally {
      loading(false);
    }
    return { response, error };
  };

  const _makeBulkRequest = async (endpoint, arg) => {
    try {
      return await endpoint(arg);
    } catch (e) {
      return e;
    }
  };

  /**
   * Method to make bulk requests. Requests are done one by one. After each request - response is gathered
   * and depending on results - specified method is called.
   *
   * @param endpoint {function} - method which make a request. Method must be bound to module, which contain method
   * to have access to module instance (this).
   * @param endpointArgs {string} - List of args passed to endpoint method
   * @param onSuccess {function | void} - method raised when all requests succeeded
   * @param onPartialSuccess {function | void} - method raised when few requests succeeded
   * @param onFail {function | void} - method raised when all requests failed
   * @param onEachSuccessRequest {function | void} - method which is raised after each succeeded request.
   * Can be used to emit some events. Method will receive response as argument.
   * @param [successCodes=[200, 201] {number[]} - list on of response codes, which will define succeeded response
   */
  const makeBulkRequest = async ({
    endpoint,
    endpointArgs = "",
    onSuccess = null,
    onPartialSuccess = null,
    onFail = null,
    onEachSuccessRequest = null,
    successCodes = [200, 201],
  }) => {
    const results = [];
    const response = ref([]);

    loading(true);
    for (const arg of endpointArgs) {
      const _response = await _makeBulkRequest(endpoint, arg);
      response.value = _response;
      results.push(_response.status);
      if (successCodes.includes(_response.status)) {
        if (response.value.data) {
          onEachSuccessRequest?.(response.value.data);
        } else {
          onEachSuccessRequest?.(response.value);
        }
      }
    }
    if (results.every((e) => successCodes.includes(e))) {
      onSuccess?.();
    } else if (results.some((e) => successCodes.includes(e))) {
      onPartialSuccess?.();
    } else {
      onFail?.();
    }
    loading(false);
    return { response };
  };
  return { isFinished, isLoading, makeRequest, makeBulkRequest };
};
