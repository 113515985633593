<template>
  <ConfirmDialog></ConfirmDialog>
  <suspense>
    <DynamicDialog />
  </suspense>
  <ConfirmPopup group="confirmPopup"></ConfirmPopup>
  <Toast data-cy="toast-message" />
  <suspense>
    <DynamicSidebar />
  </suspense>
  <div v-if="isSetupFinished">
    <TheLayout>
      <suspense><router-view /></suspense>
    </TheLayout>
  </div>
  <div v-else>
    <LogoSpinner />
  </div>
</template>

<script setup>
import { useCompanyStateManagement } from "@/queries/queriesCompany";
import { onBeforeMount, onMounted, provide, ref, watch } from "vue";
import TheLayout from "@/components/TheLayout.vue";
import { useToastManager } from "@/store/toastService";
import { useToast } from "primevue/usetoast";
import { storeToRefs } from "pinia";
import { useOpenDialogMethod } from "@/services/modals/openDialogMethod";
import { useConfirmActionMethod } from "@/services/confirm-modals/confirmDialogMethod";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";
import ConfirmPopup from "primevue/confirmpopup";
import DynamicDialog from "primevue/dynamicdialog";
import DynamicSidebar from "@/components/DynamicSidebar.vue";
import LogoSpinner from "@/components/spinners/LogoSpinner.vue";
import { useGtmScriptInjector } from "@/services/gtmManager";
import { useUserPrivacySettings } from "@/components/settings/helpers/queries";
import { useAnalyticsQueryClient } from "@/queries/queriesAnalytics";
import { useFeatureFlagManager } from "@/features";

const { prefetchCompanyState, isPrefetchComplete } =
  useCompanyStateManagement();
const { isAnalyticsEnabled } = useFeatureFlagManager();
const { prefetchAnalyticsDashboards } = useAnalyticsQueryClient();
const isSetupFinished = ref(false);

onBeforeMount(async () => {
  await prefetchCompanyState();
  if (isPrefetchComplete.value) {
    if (await isAnalyticsEnabled()) {
      await prefetchAnalyticsDashboards();
    }
  }
  isSetupFinished.value = true;
});

const { injectGtm } = useGtmScriptInjector();
const { prefetchAndVerifyUserDisableTracking } = useUserPrivacySettings();
onMounted(async () => {
  const isUserTrackingDisabled = await prefetchAndVerifyUserDisableTracking();
  if (!isUserTrackingDisabled) {
    await injectGtm();
  }
});

const toastManagerStore = useToastManager();
const toastService = useToast();
const { newToastMessages } = storeToRefs(toastManagerStore);

watch(
  () => [...toastManagerStore.toastMessages],
  () => {
    newToastMessages.value.forEach((toast) => {
      toastService.add(toast);
      toastManagerStore.setToastAsSeen(toast);
    });
  },
);

const { openDialog } = useOpenDialogMethod();
const { confirmAction } = useConfirmActionMethod();
provide("openDialog", openDialog);
provide("confirmAction", confirmAction);
</script>
