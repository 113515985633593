import { useQueryClient } from "@tanstack/vue-query";
import AnalyticsApiRequests from "@/components/analytics/helpers/analyticsRequests";
import { QUERY_KEYS } from "@/queries/queryKeys";

export const useAnalyticsQueryClient = () => {
  const queryClient = useQueryClient();

  return {
    prefetchAnalyticsDashboards: async () =>
      await queryClient.prefetchQuery({
        queryKey: QUERY_KEYS.analyticsState,
        queryFn:
          AnalyticsApiRequests.getDashboardList.bind(AnalyticsApiRequests),
        staleTime: 300000, // 5 minutes
      }),
    fetchAnalyticsDashboards: async () =>
      await queryClient
        .fetchQuery({
          queryKey: QUERY_KEYS.analyticsState,
          queryFn:
            AnalyticsApiRequests.getDashboardList.bind(AnalyticsApiRequests),
          staleTime: 300000, // 5 minutes
        })
        .catch(() => {
          return [];
        }),
  };
};
