<template>
  <div v-if="isElementArray" class="table-content__list">
    <ShipmentDetailsTableElementRenderer
      v-for="value in elementValue"
      :key="value"
      :element-value="value"
      :element-type="elementType"
      :error-message="errorMessage"
      :class="{
        'table-content__list-elem': isMoreElementsThenOne,
      }"
    />
  </div>
  <ShipmentDetailsTableElementRenderer
    v-else
    :element-value="elementValue"
    :element-type="elementType"
    :error-message="errorMessage"
  />
</template>
<script setup>
import ShipmentDetailsTableElementRenderer from "@/components/shipments/ShipmentDetailsTableElementRenderer.vue";
import { computed, toRefs } from "vue";

const props = defineProps({
  elementValue: {
    type: null, // Accepts any type
    required: true,
  },
  elementType: {
    type: String,
    required: true,
  },
  errorMessage: {
    type: String,
    default: "",
  },
});

const { elementValue } = toRefs(props);
const isElementArray = computed(() => Array.isArray(elementValue.value));
const isMoreElementsThenOne = computed(
  () => isElementArray.value && elementValue.value.length > 1,
);
</script>
<style scoped>
.table-content__list-elem {
  padding: 5.25px 10.5px;
  background: #dee2e6;
  border-radius: 16px;
}
</style>
