<template>
  <ProgressSpinner v-show="isLoading" />
  <div v-if="!isLoading">
    <WorkflowConfigurationError v-if="companyHasConfigError" />
    <div v-else>
      <div>
        <span v-if="!isViewOnly" class="p-input-icon-left">
          <FormInputText
            id="workflow-name"
            ref="workflowNameRef"
            :model-value="name"
            name="name"
            placeholder="Automation Name Here"
            data-cy="workflow-name"
            :custom-rules="inputValidation"
            @update:model-value="handleWorkflowNameChange"
          />
        </span>
      </div>
      <div class="card shadow">
        <div class="card-body" data-cy="list-workflow-routes">
          <NestedEmpty
            v-if="isEmpty"
            :heading="emptyRoutesHeading"
            :message="emptyRoutesMessage"
            icon-class="icon-diamond-campaign-small"
            btn-icon-class="pi pi-plus"
            btn-label="Add Campaign"
            style-id="empty-routes"
            @button-action="navigateToCreate"
          />
          <div v-else>
            <Message
              v-if="versionMessage"
              :closable="false"
              data-cy="previous-config-msg"
              >{{ versionMessage }}
              <a href="#" class="current-route-link" @click="navigateToLatest">
                click here.
              </a>
            </Message>
            <div class="configuration-form">
              <div id="configurationFormRoutesContainer">
                <div class="col-lg-12 mb-3">
                  <WorkflowRoutes
                    :routes="workflowRoutes"
                    :is-view-only="isViewOnly"
                    :assigned-route="assignedRoute"
                    @reorder-routes="reorderRoutes"
                    @delete-route="deleteRoute"
                    @navigate-to-edit="navigateToRouteForm"
                    @navigate-to-create="navigateToCreate"
                    @on-route-active-change="changeRouteActiveState"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="hasChanged" class="workflow-form-footer">
        <WarningText warning-text="You have unsaved changes" />
        <Button
          v-if="displaySaveWorkflowBtn"
          class="p-button right-position"
          data-cy="submit-workflow-form-btn"
          @click="saveWorkflow"
        >
          Save Changes
        </Button>
        <Button
          class="p-button right-position header-btn exit-btn"
          data-cy="discard-workflow-btn"
          @click="navigateToView('ListNotificationWorkflows')"
        >
          Discard Changes
        </Button>
      </div>
      <div v-else class="workflow-form-footer">
        <Button
          class="p-button right-position header-btn exit-btn"
          data-cy="exit-workflow-btn"
          @click="navigateToView('ListNotificationWorkflows')"
        >
          <i class="pi pi-arrow-left" />
          Back to Automations
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, toRefs, watch } from "vue";
import { CREATE, EDIT, LATEST_VERSION, SUCCESS_STATUS } from "@/constants";
import Message from "primevue/message";
import FormInputText from "@/components/FormInputText";
import WorkflowRoutes from "@/components/route-configurations/WorkflowRoutes";
import { Navigator } from "@/services/utils/navigator";
import Button from "primevue/button";
import { useWorkflowStore } from "@/components/route-configurations/stores/workflow";
import { useRouteStore } from "@/components/route-configurations/stores/route";
import { storeToRefs } from "pinia/dist/pinia";
import { isValidString } from "@/services/validation-form-schemas/utils";
import ProgressSpinner from "primevue/progressspinner";
import { onBeforeRouteLeave } from "vue-router";
import { useWorkflowsConfirmDialog } from "@/components/route-configurations/helpers/confirmModals";
import NestedEmpty from "@/components/NestedEmpty";
import WorkflowConfigurationError from "@/components/route-configurations/WorkflowConfigurationError";
import {
  useCompanyStateManagement,
  useQueryCompanyState,
} from "@/queries/queriesCompany";
import WarningText from "@/components/WarningText.vue";

const props = defineProps({
  workflowId: {
    type: String,
    default: "",
  },
  version: {
    type: String,
    default: LATEST_VERSION,
  },
  assignedRoute: {
    type: String,
    default: "",
  },
  isViewOnly: {
    type: Boolean,
    default: false,
  },
});
const { workflowId, version, isViewOnly } = toRefs(props);

const workflowStore = useWorkflowStore();
const {
  setName,
  getWorkflow,
  updateWorkflow,
  createWorkflow,
  getAvailableOptions,
  resetWorkflow,
  deleteWorkflowRoute,
  setWorkflowRoutesOrder,
  setWorkflowRoutesActiveState,
  getWorkflows,
} = workflowStore;
const {
  id,
  name,
  workflowRoutes,
  deleted,
  statusOptions,
  isLatestVersion,
  hasChanged,
  immediatelySchedule,
  workflows,
} = storeToRefs(workflowStore);

const routeStore = useRouteStore();
const { resetRoute } = routeStore;
const { confirmCancelWorkflowForm } = useWorkflowsConfirmDialog();
const { navigateToView } = Navigator();

const emptyRoutesMessage = `Personalise your customers post-purchase experience.`;
const companyHasConfigError = ref(false);

const handleWorkflowNameChange = (value) => {
  setName(value);
};

const updateInputWidth = () => {
  const wrapper = document.getElementById("workflow-name");
  if (wrapper) {
    const input = wrapper.getElementsByTagName("input")[0];
    input.style.width = "auto";
    input.style.width = `${input.scrollWidth}px`;
  }
};

const navigateToRouteForm = () => {
  navigateToView("ConfigureRoute");
};

const navigateToCreate = async () => {
  await resetRoute();
  navigateToRouteForm();
};

watch(name, () => {
  updateInputWidth();
});

watch(
  () => version.value,
  async () => {
    await resetWorkflow();
    await preparePage();
  },
);

const isEmpty = computed(() => {
  return !isLoading.value && !workflowRoutes.value.length;
});

const displaySaveWorkflowBtn = computed(() => {
  return !isEmpty.value && isLatestVersion.value && !isViewOnly.value;
});

const versionMessage = computed(() => {
  let message = "";
  if (deleted.value) {
    message = `You are viewing a historical record of this Configuration which no
    longer exists. To view your workflows, please`;
  } else if (!isLatestVersion.value) {
    message = `You are viewing an older version of this workflow which is no longer
       the current version. To view the current version, please`;
  }
  return message;
});

const emit = defineEmits(["navigateToLatest"]);

const navigateToLatest = () => {
  emit("navigateToLatest");
};

const deleteRoute = async (index) => {
  await deleteWorkflowRoute(index);
};

const reorderRoutes = async (routesVal) => {
  if (!isViewOnly.value) await setWorkflowRoutesOrder(routesVal);
};

const changeRouteActiveState = (index, newActiveState) => {
  setWorkflowRoutesActiveState(index, newActiveState);
};

const emptyRoutesHeading = computed(() => {
  if (isFetched.value) {
    return data.value.hasCreatedWorkflow
      ? "No campaigns!"
      : "Create your first campaign";
  }
  return "";
});

const isLoading = ref(false);
const { data, isFetched } = useQueryCompanyState();
const preparePage = async () => {
  isLoading.value = true;
  if (workflowId.value && !id.value) {
    await getWorkflow(workflowId.value, version.value);
  }
  if (!statusOptions.value.length) {
    await getAvailableOptions();
  }
  if (!immediatelySchedule.value) {
    companyHasConfigError.value = true;
  }
  isLoading.value = false;
};

const workflowNameRef = ref(null);
const inputValidation = isValidString({
  errorMessage: "Automation name is required",
});

const { invalidateCompanyState } = useCompanyStateManagement();
const saveWorkflow = async () => {
  const errors = await workflowNameRef.value.validateField();
  let status;
  if (!errors.value) {
    if (workflowId.value) {
      status = await updateWorkflow(
        workflowId.value,
        name.value,
        workflowRoutes.value,
      );
    } else {
      status = await createWorkflow(name.value, workflowRoutes.value);
    }
    if (status === SUCCESS_STATUS) {
      navigateToView("ListNotificationWorkflows");
      await invalidateCompanyState();
    }
  }
};

onMounted(async () => {
  await preparePage();
  await updateInputWidth();

  if (!workflows.value.length) {
    await getWorkflows();
  }
});

onBeforeRouteLeave((to, from, next) => {
  // check if the user is leaving the form page and workflow updated
  if (
    !to.path.includes("configure-campaign") &&
    hasChanged.value &&
    !companyHasConfigError.value
  ) {
    confirmCancelWorkflowForm({
      callback: () => next(),
      item: "automation",
      action: id.value ? EDIT : CREATE,
    });
  } else {
    next();
  }
});
</script>
<style scoped lang="scss">
:deep(.card) {
  margin-bottom: 24px;
}

:deep(.card-body) {
  padding: 32px;
}
.p-input-icon-left {
  max-width: 100%;
  margin-bottom: 33.5px;
}
:deep(.pi) {
  z-index: 99;
  top: 1.85rem;
}
:deep(#workflow-name input) {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9.5px 0;
  border-radius: 0;
  gap: 12px;
  position: relative;
  min-width: 300px;
  height: 48px !important;
  font-weight: 600;
  font-size: 24.5px;
  line-height: 29px;
  color: #1f2a4e;
  background: none;
  transition: all 0.3s;
  max-width: 100%;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #c7ceeb;

  &:hover,
  &:focus {
    border-color: #1f2a4e;
  }
}

:deep(.p-datatable-table) {
  .p-datatable-head {
    display: none;
  }
}

:deep(.p-message) {
  margin-top: 0;
  margin-bottom: 24px;
}

.current-route-link {
  color: #204887;
}

.workflow-form-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-right: 16px;
  }
  > :first-child {
    margin-right: 12px;
  }
  > :last-child {
    margin-right: 0;
  }
  .exit-btn {
    height: 40px;
  }
}
</style>
