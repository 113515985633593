import { createRouter, createWebHistory } from "vue-router";
import ListNotificationWorkflows from "@/components/route-configurations/ListNotificationWorkflows";
import EditNotificationWorkflow from "@/components/route-configurations/EditNotificationWorkflow";
import CreateNotificationWorkflow from "@/components/route-configurations/CreateNotificationWorkflow";
import ViewNotificationWorkflow from "@/components/route-configurations/ViewNotificationWorkflow";
import ListShipments from "@/components/shipments/ListShipments";
import NotFound from "@/components/NotFound";
import ShipmentDetails from "@/components/shipments/ShipmentDetails";
import PoliciesPrivacy from "@/components/policies/PoliciesPrivacy";
import PoliciesCookie from "@/components/policies/PoliciesCookie";
import SettingsPage from "@/components/settings/SettingsPage";
import NotificationsCreateEmail from "@/components/notifications/NotificationsCreateEmail";
import NotificationsPage from "@/components/notifications/NotificationsPage";
import NotificationsEditEmail from "@/components/notifications/NotificationsEditEmail.vue";
import DataMappingPage from "@/components/template-data-mapping/DataMappingPage.vue";
import ImportProfileCreateSteps from "@/components/csv-import/ImportProfileCreateSteps.vue";
import ImportProfileCreateStepName from "@/components/csv-import/ImportProfileCreateStepName.vue";
import ImportProfileCreateStepFile from "@/components/csv-import/ImportProfileCreateStepFile.vue";
import ImportProfileCreateStepMapping from "@/components/csv-import/ImportProfileCreateStepMapping.vue";
import ImportProfileList from "@/components/csv-import/ImportProfileList.vue";
import ImportProfileEditSteps from "@/components/csv-import/ImportProfileEditSteps.vue";
import ImportProfileEditStepName from "@/components/csv-import/ImportProfileEditStepName.vue";
import ImportProfileEditStepMapping from "@/components/csv-import/ImportProfileEditStepMapping.vue";
import FormSteps from "@/components/route-configurations/form/FormSteps";
import StepRouteName from "@/components/route-configurations/form/StepRouteName";
import StepRouteFilters from "@/components/route-configurations/form/StepRouteFilters";
import StepRouteLogic from "@/components/route-configurations/form/StepRouteLogic";
import StepRouteReview from "@/components/route-configurations/form/StepRouteReview";
import ListPastShipments from "@/components/shipments/ListPastShipments";
import WidgetPage from "@/components/widget/WidgetPage.vue";
import ListTrackingPages from "@/components/tracking-pages/ListTrackingPages.vue";
import EditTrackingPage from "@/components/tracking-pages/EditTrackingPage.vue";
import AppCore from "@/components/AppCore.vue";
import { useAuthStore } from "@/store/authStore";
import { storeToRefs } from "pinia";
import PreLoginPage from "@/components/login/PreLoginPage.vue";
import LoginPage from "@/components/login/LoginPage.vue";
import LoginComplete from "@/components/login/LoginComplete.vue";
import AnalyticsDashboard from "@/components/analytics/AnalyticsDashboard.vue";

const formatTitle = (subName) => {
  return `${subName} | Scurri Track Plus`;
};

const SSO_META_GROUP = "sso";
const AUTOMATIONS_META_GROUP = "automations";
const LIST_SHIPMENTS_META_GROUP = "shipments";
const LIST_PAST_SHIPMENTS_META_GROUP = "pastShipments";
const SHIPMENT_DETAILS_META_GROUP = "shipmentDetails";
const EMAILS_META_GROUP = "emails";
const TEMPLATE_MAPPINGS_META_GROUP = "templateMappings";
const SETTINGS_META_GROUP = "settings";
const TRACKING_WIDGET_META_GROUP = "trackingWidget";
const TRACKING_PAGE_META_GROUP = "trackingPage";
const CSV_IMPORT_META_GROUP = "csvImport";
const CSV_PROFILES_META_GROUP = "csvProfiles";
const ANALYTICS_META_GROUP = "analytics";

export const routes = [
  {
    path: "/login",
    redirect: "/pre-login",
    meta: {
      requiresAuth: false,
      title: formatTitle("Log In"),
    },
  },
  {
    path: "/pre-login",
    name: "PreLoginPage",
    component: PreLoginPage,
    meta: {
      requiresAuth: false,
      group: SSO_META_GROUP,
      title: formatTitle("Log In"),
    },
  },
  {
    path: "/sso/self-login",
    name: "LoginPageSSO",
    component: LoginPage,
    meta: {
      requiresAuth: false,
      group: SSO_META_GROUP,
      title: formatTitle("Log In"),
    },
  },
  {
    path: "/sso/complete-login",
    name: "LoginCompleteSSO",
    component: LoginComplete,
    meta: {
      requiresAuth: false,
      group: SSO_META_GROUP,
      title: formatTitle("Log In"),
    },
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: NotFound,
    meta: {
      requiresAuth: false,
      title: formatTitle("Page Not Found"),
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PoliciesPrivacy,
    meta: {
      title: formatTitle("Privacy Policy"),
    },
  },
  {
    path: "/cookie-policy",
    name: "CookiePolicy",
    component: PoliciesCookie,
    meta: {
      title: formatTitle("Cookie Policy"),
    },
  },
  {
    path: "/",
    component: AppCore,
    redirect: "/shipments",
    meta: { requiresAuth: true },
    children: [
      {
        path: "automations",
        name: "ListNotificationWorkflows",
        component: ListNotificationWorkflows,
        meta: {
          title: formatTitle("Automations"),
          group: AUTOMATIONS_META_GROUP,
        },
      },
      {
        path: "automations/create",
        name: "CreateNotificationWorkflow",
        component: CreateNotificationWorkflow,
        meta: {
          title: formatTitle("Create Automation"),
          group: AUTOMATIONS_META_GROUP,
        },
      },
      {
        path: "automations/:id/view/:version",
        name: "ViewNotificationWorkflow",
        component: ViewNotificationWorkflow,
        meta: {
          title: formatTitle("Automation Details"),
          group: AUTOMATIONS_META_GROUP,
        },
      },
      {
        path: "automations/:id/edit",
        name: "EditNotificationWorkflow",
        component: EditNotificationWorkflow,
        meta: {
          title: formatTitle("Edit Automation"),
          group: AUTOMATIONS_META_GROUP,
        },
      },
      {
        path: "automations/configure-campaign",
        name: "ConfigureRoute",
        component: FormSteps,
        meta: {
          title: formatTitle("Configure Campaign"),
          group: AUTOMATIONS_META_GROUP,
        },
        redirect: { name: "StepRouteName" },
        children: [
          {
            path: "campaign-name",
            name: "StepRouteName",
            component: StepRouteName,
          },
          {
            path: "segmentation",
            name: "StepRouteFilters",
            component: StepRouteFilters,
          },
          {
            path: "triggers",
            name: "StepRouteLogic",
            component: StepRouteLogic,
          },
          {
            path: "campaign-review",
            name: "StepRouteReview",
            component: StepRouteReview,
          },
        ],
        beforeEnter: (to, from, next) => {
          // if skipping first step or entering first step via url and not from form
          if (
            to.name !== "StepRouteName" ||
            (to.name === "StepRouteName" &&
              ![
                "CreateNotificationWorkflow",
                "EditNotificationWorkflow",
              ].includes(from.name))
          ) {
            next({ name: "ListNotificationWorkflows" });
            return;
          }
          return next();
        },
      },
      {
        path: "shipments",
        name: "ListShipments",
        component: ListShipments,
        meta: {
          title: formatTitle("Shipments"),
          group: LIST_SHIPMENTS_META_GROUP,
        },
      },
      {
        path: "past-shipments",
        name: "ListPastShipments",
        component: ListPastShipments,
        meta: {
          title: formatTitle("Past Shipments"),
          group: LIST_PAST_SHIPMENTS_META_GROUP,
        },
      },
      {
        path: "shipments/:id/",
        name: "ShipmentDetails",
        component: ShipmentDetails,
        props: (route) => ({ shipmentId: route.params.id }),
        meta: {
          title: formatTitle("Shipment Details"),
          group: SHIPMENT_DETAILS_META_GROUP,
        },
      },
      {
        path: "emails",
        name: "NotificationsPage",
        component: NotificationsPage,
        meta: {
          title: formatTitle("Email Notifications"),
          group: EMAILS_META_GROUP,
        },
      },
      {
        path: "emails/create",
        name: "CreateEmailNotification",
        component: NotificationsCreateEmail,
        meta: {
          title: formatTitle("Create Email Notification"),
          group: EMAILS_META_GROUP,
        },
      },
      {
        path: "emails/:id/edit",
        name: "EditEmailNotification",
        component: NotificationsEditEmail,
        meta: {
          title: formatTitle("Edit Email Notification"),
          group: EMAILS_META_GROUP,
        },
      },
      {
        path: "template-data-mappings",
        name: "ViewDataMappings",
        component: DataMappingPage,
        meta: {
          title: formatTitle("Template Data Mappings"),
          group: TEMPLATE_MAPPINGS_META_GROUP,
        },
      },
      {
        path: "settings",
        name: "Settings",
        component: SettingsPage,
        meta: {
          title: formatTitle("Settings"),
          group: SETTINGS_META_GROUP,
        },
      },
      {
        path: "tracking-widget",
        name: "TrackingWidget",
        component: WidgetPage,
        meta: {
          title: formatTitle("Tracking Widget"),
          group: TRACKING_WIDGET_META_GROUP,
        },
      },
      {
        path: "branded-tracking-pages",
        name: "ListTrackingPages",
        component: ListTrackingPages,
        meta: {
          title: formatTitle("Branded Tracking Pages"),
          group: TRACKING_PAGE_META_GROUP,
        },
      },
      {
        path: "branded-tracking-pages/:id/edit",
        name: "EditTrackingPage",
        component: EditTrackingPage,
        meta: {
          title: formatTitle("Edit Tracking Page"),
          group: TRACKING_PAGE_META_GROUP,
        },
      },
      {
        path: "csv-profiles/create",
        component: ImportProfileCreateSteps,
        meta: {
          title: formatTitle("CSV Import Profile"),
          group: CSV_IMPORT_META_GROUP,
        },
        redirect: { name: "CreateCsvProfileStepName" },
        children: [
          {
            path: "profile-name",
            name: "CreateCsvProfileStepName",
            component: ImportProfileCreateStepName,
          },
          {
            path: "csv-file",
            name: "CreateCsvProfileStepFile",
            component: ImportProfileCreateStepFile,
          },
          {
            path: "mapping-field",
            name: "CreateCsvProfileStepMapping",
            component: ImportProfileCreateStepMapping,
          },
        ],
        beforeEnter: (to, from, next) => {
          if (to.name !== "CreateCsvProfileStepName") {
            next({ name: "CreateCsvProfileStepName" });
            return;
          }
          return next();
        },
      },
      {
        path: "csv-profiles/:id/edit",
        component: ImportProfileEditSteps,
        meta: {
          title: formatTitle("Edit CSV Import Profile"),
          group: CSV_IMPORT_META_GROUP,
        },
        redirect: { name: "EditCsvProfileStepName" },
        children: [
          {
            path: "profile-name",
            name: "EditCsvProfileStepName",
            component: ImportProfileEditStepName,
          },
          {
            path: "edit-mapping-field",
            name: "EditCsvProfileStepMapping",
            component: ImportProfileEditStepMapping,
          },
        ],
        beforeEnter: (to, from, next) => {
          if (to.name !== "EditCsvProfileStepName") {
            next({ name: "EditCsvProfileStepName", params: to.params });
            return;
          }
          return next();
        },
      },
      {
        path: "csv-profiles",
        name: "ListCsvProfiles",
        component: ImportProfileList,
        meta: {
          title: formatTitle("CSV Import Profile"),
          group: CSV_PROFILES_META_GROUP,
        },
      },
      {
        path: "analytics/:slug",
        name: "AnalyticsDashboard",
        component: AnalyticsDashboard,
        props: (route) => ({ dashboardSlug: route.params.slug }),
        meta: {
          title: formatTitle("CSV Import Profile"),
          group: ANALYTICS_META_GROUP,
        },
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/not-found",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

const DEFAULT_TITLE = "Scurri Track Plus";

router.beforeEach(async (to) => {
  const authStore = useAuthStore();
  const { isAuthenticated } = storeToRefs(authStore);
  if (!isAuthenticated.value && to.meta.requiresAuth) {
    authStore.saveLastVisitedPage(to.path);
    return { path: "/pre-login" };
  }

  // redirects after naming updates
  if (to.path.includes("notification-workflow")) {
    to.path = to.path.replace("notification-workflow", "automation");
    return { path: to.path };
  }
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router;
